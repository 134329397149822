import React from 'react';
import { NumericFormat } from 'react-number-format';

const DecimalNumberComponent = (props) => {
  const {
    onChange,
    decimalValue = 2,
    disabled,
    value,
    className,
    ...rest
  } = props;
  const handleChange = (changedValue, sourceInfo) => {
    if (onChange) {
      onChange(changedValue?.floatValue, sourceInfo);
    }
  };
  return (
    <NumericFormat
      onValueChange={handleChange}
      className={`ant-input-affix-wrapper input-component price-component ${className}`}
      placeholder="0.00"
      decimalScale={decimalValue}
      fixedDecimalScale
      value={value}
      disabled={disabled}
      {...rest}
    />
  );
};

export default DecimalNumberComponent;
