import { Layout } from 'antd';
import React, { useEffect } from 'react';
import './App.css';
import ContentRoutes from './ContentRoutes';

const { Content } = Layout;

const App = () => {
  useEffect(() => {
    // eslint-disable-next-line no-undef
    const oldUrl = window?.location?.href;
    const splitUrl = oldUrl?.split('.');
    if (splitUrl?.[1] === 'new') {
      const newUrl = oldUrl?.replace('.new', '');
      // eslint-disable-next-line no-undef
      window?.location?.replace(newUrl);
    }
  }, []);

  return (
    <Layout id="sidePanelToggleStatus" className="gx-app-layout">
      <Content className="gx-layout-content">
        <div className="gx-main-content-wrapper">
          <ContentRoutes />
        </div>
      </Content>
    </Layout>
  );
};

export default App;
