import { useEffect } from 'react';

const IframeHeightCommunicator = () => {
  useEffect(() => {
    // Check if we're in an iframe
    // eslint-disable-next-line no-undef
    const isInIframe = window.self !== window.top;

    if (!isInIframe) {
      return;
    }

    function sendHeightToParent() {
      // eslint-disable-next-line no-undef
      const height = document?.body?.scrollHeight;

      try {
        const referrerOrigin =
          // eslint-disable-next-line no-undef
          window.self.location.ancestorOrigins?.[
            // eslint-disable-next-line no-undef
            window.self.location.ancestorOrigins.length - 1
          ];

        // Only send height to allowed origins
        if (referrerOrigin) {
          // eslint-disable-next-line no-console
          console.log('Sending height:', height, 'to:', referrerOrigin);
          // eslint-disable-next-line no-undef
          window?.parent?.postMessage({ height }, referrerOrigin);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error sending height:', error);
      }
    }

    // Set up event listeners and observers
    // eslint-disable-next-line no-undef
    window?.addEventListener('load', sendHeightToParent);
    // eslint-disable-next-line no-undef
    const resizeObserver = new ResizeObserver(sendHeightToParent);
    // eslint-disable-next-line no-undef
    resizeObserver?.observe(document.body);

    // Cleanup function
    return () => {
      // eslint-disable-next-line no-undef
      window?.removeEventListener('load', sendHeightToParent);
      resizeObserver?.disconnect();
    };
  }, []); // Empty dependency array since we only want this to run once on mount

  return null; // This component doesn't render anything
};

export default IframeHeightCommunicator;
