import React, { useContext } from 'react';
import { AppContext } from '../../AppContext';
import { DYNAMIC_FIELDS_TYPE } from '../../common/constants';
import '../styles/renderCustomWidget.less';
import RenderCustomBoolean from './RenderCustomBoolean';
import RenderCustomDateTime from './RenderCustomDateTime';
import RenderCustomNumber from './RenderCustomNumber';
import RenderCustomPickList from './RenderCustomPickList';
import RenderCustomText from './RenderCustomText';
import RenderCustomUpload from './RenderCustomUpload';

const CustomWidgetComponent = ({
  widgetConfig = null,
  pageConfigId = null,
  setSavedData,
  savedData = null,
  form = null,
  currentPageSequence = {}
}) => {
  const {
    state: { storedData }
  } = useContext(AppContext);
  const fieldType = widgetConfig?.fieldType;
  const description = widgetConfig?.config?.description;
  const descriptionValue = widgetConfig?.config?.descriptionValue;
  const data = { ...storedData };
  return (
    <div>
      {fieldType === DYNAMIC_FIELDS_TYPE?.BOOLEAN && (
        <RenderCustomBoolean
          widgetConfig={widgetConfig}
          pageConfigId={pageConfigId}
          setSavedData={setSavedData}
          savedData={savedData}
          editData={data?.customFields}
          pageId={currentPageSequence?.id}
          primaryColor={currentPageSequence?.primaryColor}
        />
      )}
      {fieldType === DYNAMIC_FIELDS_TYPE?.PICK_LIST && (
        <RenderCustomPickList
          widgetConfig={widgetConfig}
          pageConfigId={pageConfigId}
          setSavedData={setSavedData}
          savedData={savedData}
          editData={data?.customFields}
          form={form}
          pageId={currentPageSequence?.id}
          primaryColor={currentPageSequence?.primaryColor}
        />
      )}
      {fieldType === DYNAMIC_FIELDS_TYPE?.TEXT && (
        <RenderCustomText
          widgetConfig={widgetConfig}
          pageConfigId={pageConfigId}
          form={form}
          editData={data?.customFields}
          pageId={currentPageSequence?.id}
        />
      )}
      {fieldType === DYNAMIC_FIELDS_TYPE?.NUMBER && (
        <RenderCustomNumber
          widgetConfig={widgetConfig}
          pageConfigId={pageConfigId}
          form={form}
          editData={data?.customFields}
          pageId={currentPageSequence?.id}
        />
      )}
      {fieldType === DYNAMIC_FIELDS_TYPE?.DATE_TIME && (
        <RenderCustomDateTime
          widgetConfig={widgetConfig}
          pageConfigId={pageConfigId}
          form={form}
          editData={data?.customFields}
          pageId={currentPageSequence?.id}
        />
      )}
      {fieldType === DYNAMIC_FIELDS_TYPE?.UPLOAD && (
        <RenderCustomUpload
          widgetConfig={widgetConfig}
          pageConfigId={pageConfigId}
          form={form}
          editData={data?.customFields}
          pageId={currentPageSequence?.id}
        />
      )}
      {description && descriptionValue && (
        <div className="mt-37">{descriptionValue}</div>
      )}
    </div>
  );
};

export default CustomWidgetComponent;
