import { Col, Form, message, Row } from 'antd';
import { debounce, findIndex, map } from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import ReactInputMask from 'react-input-mask';
import { AppContext } from '../AppContext';
import api from '../common/api';
import { REGEX, WIDGET_TYPES } from '../common/constants';
import {
  formatPhoneNumberWithoutMask,
  formValidatorRules,
  sendAnalyticsData
} from '../common/utils';
import InputComponent from './InputComponent';
import LoaderComponent from './LoaderComponent';
import NumberComponent from './NumberComponent';
import SelectComponent from './SelectComponent';
import './styles/CustomerInfoWidget.less';

const { required, email } = formValidatorRules;

const { Option } = SelectComponent;

const CustomerInfoComponent = ({
  widgetConfig = null,
  form = null,
  setSavedData,
  pageSequencesData,
  currentPageSequence
}) => {
  const isAddressDisabled = useMemo(() => {
    return (
      pageSequencesData?.find(
        (item) => item?.pageConfiguration?.widgetType === WIDGET_TYPES?.POSTAL
      )?.order < currentPageSequence?.order
    );
  }, [pageSequencesData, currentPageSequence]);

  const [zipCodeValidationLoading, setZipCodeValidationLoading] = useState(
    false
  );
  const {
    state: { storedData },
    dispatch
  } = useContext(AppContext);

  const { zipCode } = storedData;

  const name = Form?.useWatch('name', form);
  const emailValue = Form?.useWatch('email', form);
  const mobileNumber = Form?.useWatch('mobileNumber', form);
  const street = Form?.useWatch('street', form);
  const stateValue = Form?.useWatch('state', form);
  const cityValue = Form?.useWatch('city', form);
  const zipCodeValue = Form?.useWatch('zipCode', form);

  const getLocationFromPostalCode = (zipCodeParam) => {
    if (zipCodeParam) {
      setZipCodeValidationLoading(true);
      api
        ?.get('/map-api', {
          params: {
            address: zipCodeParam
          }
        })
        .then((response) => {
          const address =
            response?.data?.results?.length > 0 &&
            response?.data?.results?.[0]?.formatted_address;

          if (address?.slice(address?.length - 3, address?.length) === 'USA') {
            const {
              lat,
              lng
            } = response?.data?.results?.[0]?.geometry?.location;
            let cityShortName;
            let stateShortName;
            let cityLongName;
            let stateLongName;
            const localityIndex = findIndex(
              response?.data?.results?.length > 0 &&
                response?.data?.results?.[0]?.address_components,
              (item) => item?.types?.includes('locality')
            );
            for (
              let i = 0;
              i < response?.data?.results?.[0]?.address_components?.length;
              // eslint-disable-next-line no-plusplus
              i++
            ) {
              for (
                let j = 0;
                j <
                response?.data?.results?.[0]?.address_components?.[i]?.types
                  ?.length;
                // eslint-disable-next-line no-plusplus
                j++
              ) {
                switch (
                  response?.data?.results?.[0]?.address_components?.[i]
                    ?.types?.[j]
                ) {
                  case 'locality':
                    if (localityIndex > -1) {
                      cityLongName =
                        response?.data?.results?.[0]?.address_components?.[i]
                          ?.long_name;
                      cityShortName =
                        response?.data?.results?.[0]?.address_components?.[i]
                          ?.short_name;
                    }
                    break;
                  case 'neighborhood':
                    if (localityIndex < 0) {
                      cityLongName =
                        response?.data?.results?.[0]?.address_components?.[i]
                          ?.long_name;
                      cityShortName =
                        response?.data?.results?.[0]?.address_components?.[i]
                          ?.short_name;
                    }
                    break;
                  case 'administrative_area_level_1':
                    stateLongName =
                      response?.data?.results?.[0]?.address_components?.[i]
                        ?.long_name;
                    stateShortName =
                      response?.data?.results?.[0]?.address_components?.[i]
                        ?.short_name;
                    break;

                  default:
                    break;
                }
              }
            }
            dispatch({
              type: 'SET_SHOW_SUBMIT_BTN',
              data: true
            });
            dispatch({
              type: 'SET_LOCATION_NAME',
              data: `${cityShortName}, ${stateShortName}`
            });
            form?.setFieldsValue({
              city: cityLongName,
              state: stateLongName
            });
            const postalCodeObj = {
              lat,
              lng,
              city: cityLongName,
              state: stateLongName,
              zipCode: zipCodeParam,
              shortAddress: `${cityShortName}, ${stateShortName}`
            };
            setSavedData(postalCodeObj);
            setZipCodeValidationLoading(false);
          } else {
            dispatch({
              type: 'SET_LOCATION_NAME',
              data: ''
            });
            setSavedData(null);
            message?.error('Location not serviceable');
            dispatch({
              type: 'SET_SHOW_SUBMIT_BTN',
              data: false
            });
            setZipCodeValidationLoading(false);
          }
        })
        .catch(() => {
          dispatch({
            type: 'SET_LOCATION_NAME',
            data: ''
          });
          setSavedData(null);
          message?.error('Please enter valid postal code.');
          dispatch({
            type: 'SET_SHOW_SUBMIT_BTN',
            data: false
          });
          setZipCodeValidationLoading(false);
        });
    }
  };

  useEffect(() => {
    if (zipCode) {
      getLocationFromPostalCode(zipCode);
      form?.setFieldsValue({
        zipCode
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zipCode]);

  useEffect(() => {
    const currentQuoteObj = { ...storedData };

    if (currentQuoteObj?.name) {
      form?.setFieldsValue({
        name: currentQuoteObj?.name
      });
    }

    if (currentQuoteObj?.email) {
      form?.setFieldsValue({
        email: currentQuoteObj?.email
      });
    }

    if (currentQuoteObj?.mobileNumber) {
      form?.setFieldsValue({
        mobileNumber: currentQuoteObj?.mobileNumber
      });
    }
    if (currentQuoteObj?.street) {
      form?.setFieldsValue({
        street: currentQuoteObj?.street
      });
    }
    if (currentQuoteObj?.state) {
      form?.setFieldsValue({
        state: currentQuoteObj?.state
      });
    }
    if (currentQuoteObj?.city) {
      form?.setFieldsValue({
        city: currentQuoteObj?.city
      });
    }
    if (currentQuoteObj?.zipCode) {
      form?.setFieldsValue({
        zipCode: currentQuoteObj?.zipCode
      });
    }
    dispatch({
      type: 'SET_SHOW_SUBMIT_BTN',
      data: true
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // analytics flow
  useEffect(() => {
    sendAnalyticsData({
      name,
      email: emailValue,
      mobileNumber: formatPhoneNumberWithoutMask(mobileNumber),
      street,
      state: stateValue,
      city: cityValue,
      zipCode: zipCodeValue
    });
  }, [
    name,
    emailValue,
    mobileNumber,
    street,
    stateValue,
    cityValue,
    zipCodeValue
  ]);

  const handleChangeZip = (e) => {
    const { value } = e?.target;
    if (value?.length < 3 || value?.length > 5 || parseInt(value, 10) === 0) {
      dispatch({
        type: 'SET_LOCATION_NAME',
        data: ''
      });
      dispatch({
        type: 'SET_SHOW_SUBMIT_BTN',
        data: false
      });
    } else if (value?.length === 5) {
      getLocationFromPostalCode(value);
    }
    if (!value) {
      form?.setFieldsValue({
        ...(widgetConfig?.addressInfo?.enable && {
          state: null,
          city: null,
          street: null
        })
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedZipHandler = useCallback(debounce(handleChangeZip, 500), []);

  return (
    <LoaderComponent spinning={zipCodeValidationLoading}>
      <div className="content-page">
        <Row gutter={20}>
          <Col span={24}>
            <Form.Item
              name="name"
              rules={[{ ...required, message: 'Please Enter Name' }]}
            >
              <InputComponent
                allowClear
                placeholder={`${widgetConfig?.personalInfo?.name?.labelText} *`}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="email"
              rules={[email, { ...required, message: 'Please Enter Email' }]}
            >
              <InputComponent
                allowClear
                placeholder={`${widgetConfig?.personalInfo?.email?.labelText} *`}
              />
            </Form.Item>
          </Col>
          {widgetConfig?.personalInfo?.phone?.enable && (
            <Col span={24}>
              <Form.Item
                name="mobileNumber"
                rules={[
                  {
                    ...required,
                    message: 'Please Enter Your Phone Number'
                  },
                  () => ({
                    validator(rule, value) {
                      if (value) {
                        // eslint-disable-next-line no-param-reassign
                        value = value?.split(' ')?.join('');
                        const numberPattern = REGEX?.PHONE;
                        if (!numberPattern?.test(value)) {
                          // eslint-disable-next-line prefer-promise-reject-errors
                          return Promise?.reject(
                            'should be a valid phone number'
                          );
                        }
                      }
                      return Promise?.resolve();
                    }
                  })
                ]}
              >
                <ReactInputMask
                  mask="(999) 999-9999"
                  placeholder={`${widgetConfig?.personalInfo?.phone?.labelText} *`}
                  allowClear
                >
                  {(inputProps) => (
                    <InputComponent
                      {...inputProps}
                      placeholder={`${widgetConfig?.personalInfo?.phone?.labelText} *`}
                    />
                  )}
                </ReactInputMask>
              </Form.Item>
            </Col>
          )}
        </Row>

        {widgetConfig?.addressInfo?.enable && (
          <>
            <div className="location-title mt-15">
              {widgetConfig?.addressInfo?.title?.labelText}
            </div>
            <Form.Item
              name="street"
              rules={[{ ...required, message: 'Please Enter Street' }]}
            >
              <InputComponent allowClear placeholder="Street*" />
            </Form.Item>

            <Form.Item
              name="zipCode"
              rules={[{ ...required, message: 'Please Enter Postal Code' }]}
            >
              <NumberComponent
                disabled={isAddressDisabled}
                allowClear
                placeholder="Postal Code*"
                onChange={(value) => {
                  dispatch({
                    type: 'SET_SHOW_SUBMIT_BTN',
                    data: false
                  });
                  debouncedZipHandler(value);
                }}
              />
            </Form.Item>
            <Row gutter={20}>
              <Col span={12}>
                <Form.Item name="state">
                  <SelectComponent placeholder="State*" disabled>
                    {map([], (state) => (
                      <Option key={state?.id} value={state?.name}>
                        {state?.name}
                      </Option>
                    ))}
                  </SelectComponent>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="city">
                  <SelectComponent placeholder="City*" disabled>
                    {map([], (city) => (
                      <Option key={city?.id} value={city?.name}>
                        {city?.name}
                      </Option>
                    ))}
                  </SelectComponent>
                </Form.Item>
              </Col>
            </Row>
            {widgetConfig?.addressInfo?.description?.enable &&
              widgetConfig?.addressInfo?.description?.labelText?.length > 0 && (
                <div
                  className="step-description"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html:
                      widgetConfig?.addressInfo?.description?.labelText || '-'
                  }}
                />
              )}
          </>
        )}
      </div>
    </LoaderComponent>
  );
};

export default CustomerInfoComponent;
