import { Checkbox, Form } from 'antd';
import React, { useContext, useEffect } from 'react';
import ReactInputMask from 'react-input-mask';
import { AppContext } from '../AppContext';
import { REGEX } from '../common/constants';
import {
  formatPhoneNumberWithoutMask,
  formValidatorRules,
  sendAnalyticsData
} from '../common/utils';
import InputComponent from './InputComponent';
import './styles/SupportWidget.less';

const { required } = formValidatorRules;

const SupportWidgetComponent = ({
  widgetConfig = null,
  form,
  refetchLeadStoreData
}) => {
  const {
    dispatch,
    state: { storedData }
  } = useContext(AppContext);

  const comments = Form?.useWatch('comments', form);
  const urgent = Form?.useWatch('urgent', form);
  const emailConfirmation = Form?.useWatch('emailConfirmation', form);
  const name = Form?.useWatch('name', form);
  const email = Form?.useWatch('email', form);
  const mobileNumber = Form?.useWatch('mobileNumber', form);

  useEffect(() => {
    refetchLeadStoreData();
    const currentQuoteObj = { ...storedData };
    if (currentQuoteObj?.leadId) {
      form?.setFieldsValue({
        comments: currentQuoteObj?.comments || null,
        mobileNumber: currentQuoteObj?.mobileNumber || null
      });
    }
    if (currentQuoteObj?.name) {
      form?.setFieldsValue({
        name: currentQuoteObj?.name
      });
    }

    if (currentQuoteObj?.email) {
      form?.setFieldsValue({
        email: currentQuoteObj?.email
      });
    }

    if (currentQuoteObj?.mobileNumber) {
      form?.setFieldsValue({
        mobileNumber: currentQuoteObj?.mobileNumber
      });
    }
    dispatch({
      type: 'SET_SHOW_SUBMIT_BTN',
      data: true
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // analytics flow
  useEffect(() => {
    // analytics flow
    sendAnalyticsData({
      comments,
      urgent,
      emailConfirmation,
      name,
      email,
      mobileNumber: formatPhoneNumberWithoutMask(mobileNumber)
    });
  }, [comments, urgent, emailConfirmation, name, email, mobileNumber]);

  return (
    <div>
      {widgetConfig?.supportFromSettings?.errorMessage?.check && (
        <div
          className="editor-render d-flex justify-center error-message"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: widgetConfig?.supportFromSettings?.errorMessage?.text || '-'
          }}
        />
      )}
      <div className="form-section fill-width">
        <span className="optional-comment">Optional comments</span>
        <div>
          <Form.Item name="comments">
            <InputComponent
              tabIndex={0}
              placeholder={
                widgetConfig?.supportFromSettings?.optionalComments?.labelText
              }
              className="mt-30"
            />
          </Form.Item>
          <div className="fill-width d-flex align-start flex-vertical">
            {widgetConfig?.supportFromSettings?.urgencyCheckboxLabelCheck && (
              <Form.Item
                name="urgent"
                initialValue={
                  widgetConfig?.supportFromSettings
                    ?.urgencyCheckboxLabelSelected
                }
                valuePropName="checked"
              >
                <Checkbox className="mt-20 common-checkbox">
                  {widgetConfig?.supportFromSettings?.urgencyCheckboxLabelText}
                </Checkbox>
              </Form.Item>
            )}

            {widgetConfig?.supportFromSettings
              ?.sendConfirmationEmailCheckboxCheck && (
              <Form.Item
                name="emailConfirmation"
                valuePropName="checked"
                initialValue={
                  widgetConfig?.supportFromSettings
                    ?.sendConfirmationEmailCheckboxSelected
                }
              >
                <Checkbox className="common-checkbox">
                  {
                    widgetConfig?.supportFromSettings
                      ?.sendConfirmationEmailCheckboxText
                  }
                </Checkbox>
              </Form.Item>
            )}
          </div>
          <Form.Item
            rules={[
              {
                required: true,
                message: `Please Enter ${widgetConfig?.supportFromSettings?.name?.label}`
              }
            ]}
            name="name"
          >
            <InputComponent
              placeholder={`${widgetConfig?.supportFromSettings?.name?.label} *`}
              className="mt-30"
            />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: `Please Enter ${widgetConfig?.supportFromSettings?.email?.labelText}`
              }
            ]}
          >
            <InputComponent
              placeholder={`${widgetConfig?.supportFromSettings?.email?.labelText} *`}
            />
          </Form.Item>
          <Form.Item
            name="mobileNumber"
            rules={[
              {
                ...required,
                message: `Please Enter ${widgetConfig?.supportFromSettings?.phone?.labelText}`
              },
              () => ({
                validator(rule, value) {
                  if (value) {
                    // eslint-disable-next-line no-param-reassign
                    value = value?.split(' ')?.join('');
                    const numberPattern = REGEX?.PHONE;
                    if (!numberPattern?.test(value)) {
                      // eslint-disable-next-line prefer-promise-reject-errors
                      return Promise?.reject(
                        `should be a valid ${widgetConfig?.supportFromSettings?.phone?.labelText}`
                      );
                    }
                  }
                  return Promise?.resolve();
                }
              })
            ]}
          >
            <ReactInputMask
              mask="(999) 999-9999"
              placeholder={`${widgetConfig?.supportFromSettings?.phone?.labelText} *`}
              allowClear
            >
              {(inputProps) => <InputComponent {...inputProps} />}
            </ReactInputMask>
          </Form.Item>
        </div>
      </div>
    </div>
  );
};

export default SupportWidgetComponent;
