import { DatePicker } from 'antd';
import moment from 'moment';
import React from 'react';
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATE_TIME_FORMAT
} from '../common/constants';

const { RangePicker } = DatePicker;

const RangePickerComponent = (props) => {
  const {
    showTime = false,
    disabledDate = false,
    showToday = false,
    dateFormat = DEFAULT_DATE_FORMAT,
    disablePastDate = false,
    disableFutureDate = false,
    dateTimeFormat = DEFAULT_DATE_TIME_FORMAT,
    refs,
    isCurrentDateAllowed = false,
    ...rest
  } = props;

  const disabledDateFun = (current) => {
    if (disableFutureDate) {
      if (isCurrentDateAllowed) {
        return current && current > moment()?.endOf('day');
      }
      return current && current > moment()?.startOf('day');
    }
    if (disablePastDate) {
      // Can not select days before today
      if (isCurrentDateAllowed) {
        return current && current < moment()?.endOf('day');
      }
      return current && current < moment()?.startOf('day');
    }
  };

  return (
    <RangePicker
      bordered
      format={showTime ? dateTimeFormat : dateFormat}
      showTime={showTime}
      showToday={showToday}
      disabledDate={disabledDate && disabledDateFun}
      popupClassName="common-range-picker-dropdown"
      ref={refs}
      {...rest}
    />
  );
};

export default RangePickerComponent;
