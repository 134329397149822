import React from 'react';

const PickListCheckedIconComponent = ({
  color = '#1890ff',
  className = ''
}) => {
  return (
    <svg
      width="64"
      height="75"
      viewBox="0 0 64 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className || ''}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 59C46.3594 59 58 47.3594 58 33C58 18.6406 46.3594 7 32 7C17.6406 7 6 18.6406 6 33C6 47.3594 17.6406 59 32 59ZM42.7599 23.25L28.04 37.9699L21.2401 31.17L19 33.4101L28.04 42.4501L45 25.4901L42.7599 23.25Z"
        fill="white"
      />
      <circle cx="32" cy="33" r="23.25" stroke={color} strokeWidth="1.5" />
    </svg>
  );
};

export default PickListCheckedIconComponent;
