import { Card, Result } from 'antd';
import React from 'react';
import { Helmet } from 'react-helmet';

const Error404 = () => (
  <>
    <Helmet>
      <title>Page Not Found</title>
    </Helmet>
    <Card className="full-height-card card-body-padding">
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
      />
    </Card>
  </>
);

export default Error404;
