import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../AppContext';

const GeneralSection = ({
  pageConfiguration,
  currentPageSequence = null,
  globalHeaderImage = null
}) => {
  const {
    showLogo = false,
    showTaglineAbove = false,
    taglineAbove = '',
    showTaglineBelow = false,
    taglineBelow = '',
    showHeaderImage = false,
    showTitle = false,
    title = '',
    headerImage = null
  } = pageConfiguration || {};

  const {
    state: { locationName }
  } = useContext(AppContext);

  const [firstLogo, setFirstLogo] = useState(null);
  const [secondLogo, setSecondLogo] = useState(null);

  useEffect(() => {
    const logoType = currentPageSequence?.brandConfig?.logo;

    switch (logoType) {
      case 'TENANT':
        setFirstLogo(currentPageSequence?.tenantLogo?.url);
        setSecondLogo(null);
        break;

      case 'BRAND':
        setFirstLogo(currentPageSequence?.logo?.url);
        setSecondLogo(null);
        break;

      case 'BOTH':
        setFirstLogo(currentPageSequence?.logo?.url);
        setSecondLogo(currentPageSequence?.tenantLogo?.url);
        break;

      default:
        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="general-section">
      {showHeaderImage && (headerImage?.url || globalHeaderImage) && (
        <div className="full-width-image">
          <img
            alt="header-img"
            height={250}
            className="header-image"
            src={headerImage?.url || globalHeaderImage}
          />
        </div>
      )}
      {showLogo && (
        <>
          {firstLogo?.length > 0 && (
            <div className="logo-section">
              <img alt="logo" src={firstLogo} />
            </div>
          )}
          {secondLogo?.length > 0 && (
            <div className="logo-section">
              <img className="second-logo" alt="logo" src={secondLogo} />
              <span className="logo-text">
                Managed By {currentPageSequence?.tenantName}
              </span>
            </div>
          )}
        </>
      )}
      {showTaglineAbove && taglineAbove?.length > 0 && (
        <div className="thin-label mt-25">
          <span>{taglineAbove}</span>
        </div>
      )}
      {locationName?.length > 0 &&
      pageConfiguration?.widgetType === 'POSTAL' &&
      pageConfiguration?.widgetConfiguration?.zipCodeWidget
        ?.replaceTitleWithLocation ? (
        <div className="title-section mt-4">
          <span className="thin-label">Your installation location is</span>
          <span className="bold-label">{locationName}</span>
        </div>
      ) : (
        showTitle &&
        title?.length > 0 && (
          <div className="bold-label mt-4">
            <span>{title}</span>
          </div>
        )
      )}
      {showTaglineBelow && taglineBelow?.length > 0 && (
        <div className="thin-label mt-4">
          <span>{taglineBelow}</span>
        </div>
      )}
    </div>
  );
};

export default GeneralSection;
