import React from 'react';

const LocationIconComponent = ({ color = '#1890ff' }) => {
  return (
    <svg
      width="13"
      height="16"
      viewBox="0 0 13 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.93849 14.6241L6.5 15.007L6.06151 14.6241C2.37421 11.4044 0.5 8.59312 0.5 6.12195C0.5 2.62239 3.23137 0.121948 6.5 0.121948C9.76863 0.121948 12.5 2.62239 12.5 6.12195C12.5 8.59312 10.6258 11.4044 6.93849 14.6241ZM4.5 6.12195C4.5 5.01738 5.39543 4.12195 6.5 4.12195C7.60457 4.12195 8.5 5.01738 8.5 6.12195C8.5 7.22652 7.60457 8.12195 6.5 8.12195C5.39543 8.12195 4.5 7.22652 4.5 6.12195Z"
        fill={color}
      />
    </svg>
  );
};

export default LocationIconComponent;
