import React, { useContext } from 'react';
import { AppContext } from '../../AppContext';
import { DYNAMIC_FIELDS_TYPE } from '../../common/constants';
import RenderSystemBoolean from './RenderSystemBoolean';
import RenderSystemNumber from './RenderSystemNumber';
import RenderSystemPickList from './RenderSystemPickList';
import RenderSystemText from './RenderSystemText';

const SystemWidgetComponent = ({
  widgetConfig = null,
  setSavedData,
  savedData = null,
  form = null,
  fieldConfig = null,
  defaultPickListOption = null,
  currentPageSequence = {}
}) => {
  const {
    state: { storedData }
  } = useContext(AppContext);
  const data = { ...storedData };

  const fieldType =
    widgetConfig?.selectedSystemFieldBlock?.selectedSystemFieldType;

  return (
    <div>
      {fieldType === DYNAMIC_FIELDS_TYPE?.BOOLEAN && (
        <RenderSystemBoolean
          widgetConfig={widgetConfig}
          setSavedData={setSavedData}
          savedData={savedData}
          editData={data?.systemFields}
          currentPageSequence={currentPageSequence}
        />
      )}
      {fieldType === DYNAMIC_FIELDS_TYPE?.NUMBER && (
        <RenderSystemNumber
          widgetConfig={widgetConfig}
          editData={data?.systemFields}
          form={form}
          fieldConfig={fieldConfig}
        />
      )}
      {fieldType === DYNAMIC_FIELDS_TYPE?.PICK_LIST && (
        <RenderSystemPickList
          widgetConfig={widgetConfig}
          editData={data?.systemFields}
          defaultPickListOption={defaultPickListOption}
          savedData={savedData}
          setSavedData={setSavedData}
          form={form}
          pageId={currentPageSequence?.id}
          primaryColor={currentPageSequence?.primaryColor}
          type={DYNAMIC_FIELDS_TYPE?.PICK_LIST}
        />
      )}
      {fieldType === DYNAMIC_FIELDS_TYPE?.ENUM_PICK_LIST && (
        <RenderSystemPickList
          widgetConfig={widgetConfig}
          editData={data?.systemFields}
          defaultPickListOption={defaultPickListOption}
          savedData={savedData}
          setSavedData={setSavedData}
          form={form}
          pageId={currentPageSequence?.id}
          primaryColor={currentPageSequence?.primaryColor}
          type={DYNAMIC_FIELDS_TYPE?.ENUM_PICK_LIST}
        />
      )}
      {fieldType === DYNAMIC_FIELDS_TYPE?.TEXT && (
        <RenderSystemText
          widgetConfig={widgetConfig}
          savedData={savedData}
          form={form}
          editData={data?.systemFields}
          fieldConfig={fieldConfig}
        />
      )}
    </div>
  );
};

export default SystemWidgetComponent;
