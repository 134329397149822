import { InfoCircleOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { sendAnalyticsData, strippedString } from '../../common/utils';
import EditorComponent from '../EditorComponent';
import InputComponent from '../InputComponent';

const RenderCustomText = ({
  widgetConfig = null,
  editData = null,
  pageId = null,
  form = null
}) => {
  const textType = widgetConfig?.config?.textType;
  const minLength = Number(widgetConfig?.config?.textLength?.minLength);
  const maxLength = Number(widgetConfig?.config?.textLength?.maxLength);
  const label = widgetConfig?.config?.label;
  const toolTipChecked = widgetConfig?.config?.tooltip;
  const toolTipValue = widgetConfig?.config?.tooltipValue;
  const required = widgetConfig?.config?.rules?.required;
  const defaultCheck = widgetConfig?.config?.defaultCheck;
  const defaultValue = widgetConfig?.config?.default;
  const hideLabel = widgetConfig?.config?.rules?.hideLabel;
  const readOnly = widgetConfig?.config?.rules?.readOnly;

  const inputValue = Form?.useWatch(['customFields', pageId], form);

  // analytics flow
  useEffect(() => {
    sendAnalyticsData({ [label]: inputValue });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  useEffect(() => {
    if (!isEmpty(editData?.[pageId])) {
      form?.setFieldsValue({
        customFields: {
          [pageId]: editData?.[pageId]?.[0]
        }
      });
    } else if (defaultCheck) {
      form?.setFieldsValue({
        customFields: {
          [pageId]: [defaultValue]
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, defaultCheck, editData]);

  const renderText = () => {
    if (textType === 'MULTI_LINE') {
      return (
        <InputComponent.TextArea
          showCount
          readOnly={readOnly}
          rows={3}
          className="common-textarea mb-16"
          maxLength={maxLength}
          minLength={minLength}
          placeholder={`Enter your ${label}`}
        />
      );
    }
    if (textType === 'RICH_TEXT') {
      return (
        <EditorComponent
          placeholder={`Enter your ${label}`}
          readOnly={readOnly}
        />
      );
    }
    return (
      <InputComponent
        name="Value"
        showCount
        readOnly={readOnly}
        maxLength={maxLength}
        minLength={minLength}
        placeholder={`Enter your ${label}`}
      />
    );
  };

  const getRulesBasedOnCondition = () => {
    let rules = [];
    if (readOnly) {
      if (textType !== 'RICH_TEXT') {
        rules = [
          {
            required,
            message: 'This field is required. Please fill it in.'
          },
          {
            max: maxLength,
            message: `Please Enter Max ${maxLength} Characters`
          },
          {
            min: minLength,
            message: `Please Enter Min ${minLength} Characters`
          }
        ];
      } else {
        rules = [
          {
            validator(_, value) {
              const stringLength = strippedString(value)?.length;
              if (required && (!value || !stringLength)) {
                return Promise?.reject(
                  new Error('This field is required. Please fill it in.')
                );
              }

              if (stringLength > maxLength) {
                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise?.reject(
                  `Please Enter Max ${maxLength} Characters`
                );
              }
              if (stringLength < minLength) {
                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise?.reject(
                  `Please Enter Min ${minLength} Characters`
                );
              }
              return Promise?.resolve();
            }
          }
        ];
      }
    }
    return rules;
  };

  return (
    <div className="mt-37">
      <Form.Item
        initialValue={defaultCheck ? defaultValue : ''}
        name={['customFields', pageId]}
        label={hideLabel ? ' ' : label}
        required={required}
        tooltip={
          toolTipChecked && {
            title: toolTipValue,
            icon: <InfoCircleOutlined />
          }
        }
        rules={getRulesBasedOnCondition}
        className={`${!required && 'hide-required-mark'} ${
          toolTipChecked && 'label-with-tooltip'
        }`}
      >
        {renderText()}
      </Form.Item>
    </div>
  );
};

export default RenderCustomText;
