import { LeftOutlined } from '@ant-design/icons';
import {
  DrawingManager,
  GoogleMap,
  Marker,
  Polygon,
  useLoadScript
} from '@react-google-maps/api';
import { Button } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import LoaderComponent from '../LoaderComponent';
import ModalComponent from '../ModalComponent';

const libraries = ['drawing', 'places'];

const options = {
  drawingControl: false,
  drawingControlOptions: {
    drawingModes: ['polygon']
  },
  polygonOptions: {
    fillColor: `#2196F3`,
    strokeColor: `#2196F3`,
    fillOpacity: 0.5,
    strokeWeight: 2,
    clickable: true,
    editable: true,
    draggable: true,
    zIndex: 1
  }
};

const GooglMapComponent = ({
  shortAddress = null,
  modalConfig = null,
  tenantLogo = null,
  setShowHighLightYourAreaModal,
  showTips = false,
  setShowTips,
  measurementTipsModalConfig = null,
  area = 0,
  setArea,
  setSelectedTab
}) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process?.env?.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries
  });

  const [path, setPath] = useState([]);
  const [disableButtons, setDisableButtons] = useState(true);
  const [center, setCenter] = useState({
    lat: 39.42654846155253,
    lng: -101.30331157734375
  });
  const [state, setState] = useState({
    drawingMode: 'polygon'
  });

  const handleGeocode = () => {
    if (typeof window !== 'undefined') {
      // eslint-disable-next-line no-undef
      const geocoder = new window.google.maps.Geocoder();

      geocoder.geocode({ address: shortAddress }, (results, status) => {
        if (status === 'OK') {
          const { location } = results?.[0]?.geometry;
          const lat = location?.lat();
          const lng = location?.lng();
          setCenter({ lat, lng });
        } else {
          // eslint-disable-next-line no-console
          console.error(
            'Geocode was not successful for the following reason: ',
            status
          );
        }
      });
    }
  };

  useEffect(() => {
    if (isLoaded && typeof window !== 'undefined') {
      handleGeocode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);

  const noDraw = () => {
    setState(function set(prevState) {
      return { ...prevState, drawingMode: 'maker' };
    });
  };

  const onPolygonComplete = React.useCallback(
    function onPolygonComplete(poly) {
      const polyArray = poly?.getPath()?.getArray();
      const paths = [];
      polyArray?.forEach(function (polyPath) {
        paths?.push({ lat: polyPath?.lat(), lng: polyPath?.lng() });
      });
      setPath(paths);
      // eslint-disable-next-line no-undef
      const areaCopy = google?.maps?.geometry?.spherical?.computeArea(
        poly?.getPath()
      );
      // 1 square meter= 10.76391042 square feet.
      const areaInSquareFeet = areaCopy * 10.76391042;
      setArea(parseFloat(areaInSquareFeet?.toFixed(2))); // check for SQ/ft convert
      setDisableButtons(false);
      noDraw();
      poly?.setMap(null);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [path]
  );

  // Define refs for Polygon instance and listeners
  const polygonRef = useRef(null);
  const listenersRef = useRef([]);
  const drawingManagerRef = useRef();

  const onLoadDrawingManager = (drawingManager) => {
    drawingManagerRef.current = drawingManager;
  };
  // Call setPath with new edited path
  const onEdit = useCallback(() => {
    if (polygonRef?.current) {
      const nextPath = polygonRef?.current
        ?.getPath()
        ?.getArray()
        ?.map((latLng) => {
          return { lat: latLng?.lat(), lng: latLng?.lng() };
        });
      setPath(nextPath);
      // eslint-disable-next-line no-undef
      const areaCopy = google?.maps?.geometry?.spherical?.computeArea(nextPath);
      // 1 square meter= 10.76391042 square feet.
      const areaInSquareFeet = areaCopy * 10.76391042;
      setArea(areaInSquareFeet?.toFixed(2));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setPath]);

  // Bind refs to current Polygon and listeners
  const onLoad = useCallback(
    (polygon) => {
      polygonRef.current = polygon;
      const paths = polygon?.getPath();
      listenersRef?.current?.push(
        paths?.addListener('set_at', onEdit),
        paths?.addListener('insert_at', onEdit),
        paths?.addListener('remove_at', onEdit)
      );
    },
    [onEdit]
  );

  // Clean up refs
  const onUnmount = useCallback(() => {
    listenersRef?.current?.forEach((lis) => lis?.remove());
    polygonRef.current = null;
  }, []);

  const centerMapFunc = () => {
    return center;
  };

  const handleClear = () => {
    drawingManagerRef?.current?.setDrawingMode(null);
    polygonRef?.current?.setMap(null);
    setArea(0);
    setPath([]);
    setDisableButtons(true);
    setState({
      drawingMode: 'polygon'
    });
    drawingManagerRef?.current?.setDrawingMode('polygon');
  };

  const handleBack = () => {
    polygonRef?.current?.setMap(null);
    setArea(0);
    setPath([]);
    setState({
      drawingMode: 'polygon'
    });
    setShowHighLightYourAreaModal(false);
  };

  if (!isLoaded) {
    return <LoaderComponent />;
  }

  if (loadError) return 'Error Loading Maps..!';

  return (
    <div className="main-wrapper">
      <div className="area-show top-section d-flex flex-vertical justify-center align-center">
        <div className="dimension-logo-section">
          <img src={tenantLogo} alt="tenant" />
        </div>
        <div className="sub-title-text">{modalConfig?.title}</div>
        {modalConfig?.descriptionCheck && (
          <div
            className="description"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: modalConfig?.descriptionText || '-'
            }}
          />
        )}
      </div>
      {showTips && (
        <ModalComponent
          open={showTips}
          setOpen={setShowHighLightYourAreaModal}
          onCancel={() => setShowHighLightYourAreaModal(false)}
          footer={null}
          destroyOnClose
          wrapClassName="measurement-tips-modal"
          closable={false}
        >
          <div className="measurement-tip-wrapper">
            <div className="title">{measurementTipsModalConfig?.titleText}</div>
            <div
              className="tips-content editor-render"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: measurementTipsModalConfig?.descriptionText || '-'
              }}
            />
            <div className="cta-button mt-10">
              <Button
                className="common-button"
                type="primary"
                onClick={() => setShowTips(false)}
              >
                {measurementTipsModalConfig?.actionButtonLabel}
              </Button>
            </div>
          </div>
        </ModalComponent>
      )}
      <GoogleMap
        // eslint-disable-next-line no-undef
        mapTypeId={google?.maps?.MapTypeId?.SATELLITE}
        tilt={0}
        mapContainerClassName="common-map"
        zoom={20}
        center={centerMapFunc()}
        options={{
          disableDefaultUI: true,
          // eslint-disable-next-line no-undef
          mapTypeId: google?.maps?.MapTypeId?.SATELLITE
        }}
      >
        {path?.length === 0 ? (
          <DrawingManager
            onLoad={onLoadDrawingManager}
            drawingMode={state?.drawingMode}
            options={options}
            onPolygonComplete={onPolygonComplete}
            editable
            draggable
            // Event used when manipulating and adding points
            onMouseUp={onEdit}
            // Event used when dragging the whole Polygon
            onDragEnd={onEdit}
          />
        ) : (
          <Polygon
            options={{
              fillColor: `#2196F3`,
              strokeColor: `#2196F3`,
              fillOpacity: 0.5,
              strokeWeight: 2
            }}
            // Make the Polygon editable / draggable
            editable
            draggable
            path={path}
            // Event used when manipulating and adding points
            onMouseUp={onEdit}
            // Event used when dragging the whole Polygon
            onDragEnd={onEdit}
            onLoad={onLoad}
            onUnmount={onUnmount}
          />
        )}
        {path?.map((pos, index) => {
          // eslint-disable-next-line react/no-array-index-key
          return <Marker key={index} label={`${index}`} position={pos} />;
        })}
      </GoogleMap>

      <div className="area-show bottom-section d-flex flex-vertical justify-center align-center">
        <div className="d-flex flex-vertical text-center fill-width">
          {area <= 0 && (
            <span className="hint">Hint :&nbsp;{modalConfig?.hint}</span>
          )}
          {area > 0 && (
            <>
              <span className="area-label">{modalConfig?.resultLabel}</span>
              <span key={area} className="area-value">
                Total Area {area} Sq/ft.
              </span>
            </>
          )}
          <div className="d-flex justify-between align-center">
            <Button
              onClick={() => {
                setSelectedTab('MANUAL_ENTRY');
                setShowHighLightYourAreaModal(false);
              }}
              className="common-button action-buttons"
              type="primary"
              disabled={disableButtons}
            >
              {modalConfig?.actionButtonLabel}
            </Button>
            <Button onClick={handleClear} className="action-buttons">
              Clear
            </Button>
          </div>
        </div>
        <div className="measurement-tip-link" onClick={() => setShowTips(true)}>
          {modalConfig?.tipsButtonLabel}
        </div>
        <div className="d-flex justify-center">
          <Button
            icon={<LeftOutlined />}
            className="back-btn"
            onClick={handleBack}
          >
            Back
          </Button>
        </div>
      </div>
    </div>
  );
};

export default GooglMapComponent;
