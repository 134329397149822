import { GoogleMap, Marker } from '@react-google-maps/api';
import { React, useEffect, useState } from 'react';

const mapOptions = {
  clickableIcons: false,
  disableDefaultUI: true,
  zoomControl: false,
  gestureHandling: 'none',
  keyboardShortcuts: false,
  styles: [
    {
      elementType: 'geometry',
      stylers: [
        {
          color: '#f5f5f5'
        }
      ]
    },
    {
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#616161'
        }
      ]
    },
    {
      elementType: 'labels.text.stroke',
      stylers: [
        {
          color: '#f5f5f5'
        }
      ]
    },
    {
      featureType: 'administrative.land_parcel',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#bdbdbd'
        }
      ]
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [
        {
          color: '#eeeeee'
        }
      ]
    },
    {
      featureType: 'poi',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#757575'
        }
      ]
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [
        {
          color: '#e5e5e5'
        }
      ]
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e'
        }
      ]
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [
        {
          color: '#ffffff'
        }
      ]
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#757575'
        }
      ]
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [
        {
          color: '#dadada'
        }
      ]
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#616161'
        }
      ]
    },
    {
      featureType: 'road.local',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e'
        }
      ]
    },
    {
      featureType: 'transit.line',
      elementType: 'geometry',
      stylers: [
        {
          color: '#e5e5e5'
        }
      ]
    },
    {
      featureType: 'transit.station',
      elementType: 'geometry',
      stylers: [
        {
          color: '#eeeeee'
        }
      ]
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [
        {
          color: '#c9c9c9'
        }
      ]
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e'
        }
      ]
    }
  ]
};

const center = { lat: 39.42654846155253, lng: -101.30331157734375 };

const MarkerComponent = ({ markerObj, fillColor }) => {
  const template = [
    '<?xml version="1.0"?>',
    '<svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">',
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M6.39443 13.452L5.99979 13.7966L5.60516 13.452C2.28658 10.5543 0.599792 8.02408 0.599792 5.80002C0.599792 2.65042 3.05802 0.400024 5.99979 0.400024C8.94156 0.400024 11.3998 2.65042 11.3998 5.80002C11.3998 8.02408 9.71301 10.5543 6.39443 13.452ZM4.19979 5.80002C4.19979 4.80591 5.00568 4.00002 5.99979 4.00002C6.99391 4.00002 7.79979 4.80591 7.79979 5.80002C7.79979 6.79414 6.99391 7.60002 5.99979 7.60002C5.00568 7.60002 4.19979 6.79414 4.19979 5.80002Z" fill="{{ color }}"/>',
    '</svg>'
  ]?.join('\n');
  const svg = template?.replace('{{ color }}', fillColor);
  return (
    <Marker
      icon={{
        // eslint-disable-next-line no-undef
        url: `data:image/svg+xml;charset=UTF-8;base64,${btoa(svg)}`,
        // eslint-disable-next-line no-undef
        scaledSize: new window.google.maps.Size(30, 30),
        // eslint-disable-next-line no-undef
        origin: new window.google.maps.Point(0, 0),
        // eslint-disable-next-line no-undef
        anchor: new window.google.maps.Point(15, 15)
      }}
      position={{ lat: markerObj?.lat, lng: markerObj?.lng }}
    />
  );
};

const GoogleMapComponent = ({
  markerObj = null,
  fillColor = '#1890ff',
  loadError
}) => {
  const [marker, setMarker] = useState(null);

  useEffect(() => {
    setMarker(markerObj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markerObj]);

  if (loadError) return 'Error Loading Maps..!';

  const centerMap = () => {
    if (markerObj) {
      return markerObj;
    }
    return center;
  };

  return (
    <GoogleMap
      mapContainerClassName="common-map-cpq"
      zoom={!markerObj ? 3 : 12}
      center={centerMap()}
      options={mapOptions}
    >
      {marker && (
        <>
          <MarkerComponent markerObj={marker} fillColor={fillColor} />
        </>
      )}
    </GoogleMap>
  );
};

export default GoogleMapComponent;
