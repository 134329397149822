import {
  FileExcelOutlined,
  FileOutlined,
  FilePdfOutlined,
  FilePptOutlined,
  FileTextOutlined,
  FileWordOutlined,
  InfoCircleOutlined,
  PlusOutlined
} from '@ant-design/icons';
import { Form, Upload } from 'antd';
import { filter, map } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  formValidatorRules,
  getBase64,
  getPublicUrl,
  sendAnalyticsData
} from '../../common/utils';
import PreviewModal from '../PreviewModal';

const { dynamicFieldsUpload } = formValidatorRules;

const RenderCustomUpload = ({
  widgetConfig = null,
  editData = null,
  pageId = null,
  form = null
}) => {
  const allowedMultiple = widgetConfig?.config?.allowMultiple;

  const toolTipChecked = widgetConfig?.config?.tooltip || widgetConfig?.tooltip;
  const toolTipValue =
    widgetConfig?.config?.tooltipValue || widgetConfig?.tooltipValue;
  const required = widgetConfig?.config?.rules?.required;
  const hideLabel = widgetConfig?.config?.rules?.hideLabel;
  const fileTypes = widgetConfig?.config?.fileType?.value;
  const widgetTypes = widgetConfig?.config?.fileType?.key;

  const {
    label = '',
    size = 0,
    limitFileSize = false,
    maxFile = 0
  } = widgetConfig?.config;
  const [fileList, setFileList] = useState([]);

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewTitle, setPreviewTitle] = useState('');
  const [previewImage, setPreviewImage] = useState('');

  // analytics flow
  useEffect(() => {
    sendAnalyticsData({
      [label]: allowedMultiple
        ? map(fileList, (item) => ({ name: item?.name, url: item?.url }))
        : { name: fileList?.[0]?.name, url: fileList?.[0]?.url }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList]);

  const handleRemove = async (file) => {
    const filteredFileList = filter(
      fileList,
      (item) => item?.uid !== file?.uid
    );
    setFileList(filteredFileList);

    if (filteredFileList?.length > 0) {
      form?.setFieldsValue({
        customFields: {
          [pageId]: filteredFileList
        }
      });
    } else {
      form?.setFieldsValue({
        customFields: {
          [pageId]: null
        }
      });
    }
  };

  const handleChange = async ({ file, fileList: files }) => {
    if (file?.status === 'removed') {
      return;
    }
    setFileList(files);
  };

  const customOrigin = (originNode, file) => {
    const {
      props: nodeProps,
      props: { children }
    } = originNode;
    const ext = file?.name?.substring(file?.name?.lastIndexOf('.') + 1);
    const newChildren = [children?.[1], children?.[2]];
    let icon = '';
    switch (ext) {
      case 'xlsx':
      case 'xls':
      case 'csv':
        icon = <FileExcelOutlined className="file-type" />;
        break;
      case 'pdf':
        icon = <FilePdfOutlined className="file-type" />;
        break;
      case 'ppt':
      case 'pptx':
        icon = <FilePptOutlined className="file-type" />;
        break;
      case 'doc':
      case 'docx':
        icon = <FileWordOutlined className="file-type" />;
        break;
      case 'txt':
        icon = <FileTextOutlined className="file-type" />;
        break;
      case 'mp4':
        icon = <FileOutlined className="file-type" />;
        break;
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        return originNode;
      default:
        icon = <FileOutlined className="file-type" />;
        break;
    }

    return (
      <div className={nodeProps?.className}>
        <div className="ant-upload-list-item-info ">{icon}</div>
        {newChildren}
      </div>
    );
  };

  const renderUploadIcon = () => {
    if (allowedMultiple) {
      if (fileList?.length < maxFile) {
        return true;
      }
      return false;
    }
    if (fileList?.length < 1) {
      return true;
    }
    return false;
  };

  const handlePreview = async (fileObj) => {
    let preview;
    if (!fileObj?.url && !fileObj?.preview) {
      preview = await getBase64(fileObj?.originFileObj);
    }
    setPreviewVisible(true);
    setPreviewTitle(
      fileObj?.name ||
        fileObj?.title ||
        fileObj?.url?.substring(fileObj?.url?.lastIndexOf('/') + 1)
    );

    setPreviewImage(fileObj?.url || preview);
  };

  const getPublicImages = async (imageArray) => {
    const images = await getPublicUrl({ logoObject: imageArray });
    if (images) {
      setFileList(images);
      form?.setFieldsValue({
        customFields: {
          [pageId]: [...images]
        }
      });
      return images;
    }
    setFileList([]);
    form?.setFieldsValue({
      customFields: {
        [pageId]: []
      }
    });
  };

  useEffect(() => {
    if (
      editData?.[pageId]?.[0]?.url ||
      editData?.[pageId]?.[0]?.fileList?.length > 0
    ) {
      getPublicImages(editData?.[pageId]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData]);

  return (
    <>
      <PreviewModal
        previewImage={previewImage}
        previewTitle={previewTitle}
        previewVisible={previewVisible}
        setPreviewVisible={setPreviewVisible}
      />
      <Form.Item
        name={['customFields', pageId]}
        label={hideLabel ? ' ' : label}
        tooltip={
          toolTipChecked && {
            title: toolTipValue,
            icon: <InfoCircleOutlined />
          }
        }
        rules={[
          dynamicFieldsUpload({
            fieldRequired: required,
            fileValue: fileTypes,
            maxFile,
            size,
            limitFileSize
          })
        ]}
        className={`${!required && 'hide-required-mark'} ${
          toolTipChecked && 'label-with-tooltip'
        }`}
      >
        <Upload
          fileList={fileList}
          onChange={handleChange}
          multiple={allowedMultiple}
          maxCount={maxFile}
          listType={maxFile ? 'picture-card' : 'picture'}
          showUploadList={{
            showDownloadIcon: true,
            showPreviewIcon: true,
            showRemoveIcon: true
          }}
          tabIndex={0}
          beforeUpload={() => {
            return false;
          }}
          onPreview={widgetTypes === 'IMAGE' && handlePreview}
          itemRender={(originNode, file) => (
            <div>
              {customOrigin(originNode, file)}
              <div
                title={
                  file?.name?.split('.')?.slice(0, -1)?.join('.') || file?.label
                }
                className="gallery-label"
              >
                {file?.name?.split('.')?.slice(0, -1)?.join('.') || file?.label}
              </div>
            </div>
          )}
          className="render-upload"
          onRemove={handleRemove}
        >
          {renderUploadIcon() && (
            <div className="upload-content">
              <span className="icon-header">
                <PlusOutlined />
              </span>
              <span className="upload-title">Upload</span>
              <span className="upload-description">
                ...one or multiple files
              </span>
            </div>
          )}
        </Upload>
      </Form.Item>
    </>
  );
};

export default RenderCustomUpload;
