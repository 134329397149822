import React from 'react';

const BooleanChecked = ({ selected = false, primaryColor = '#1890ff' }) => {
  const color = selected ? 'white' : primaryColor;

  return (
    <svg
      width="76"
      height="75"
      viewBox="0 0 76 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.3148 41.4754L46.4113 30.3789L48.1 32.0676L35.3148 44.8528L28.5 38.038L30.1887 36.3493L35.3148 41.4754Z"
        fill={color}
      />
      <circle
        cx="38.5"
        cy="37.3789"
        r="23.25"
        stroke={color}
        strokeWidth="2.5"
      />
    </svg>
  );
};

export default BooleanChecked;
