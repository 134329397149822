import { Checkbox, Col, Form, Row, Tabs } from 'antd';
import { find, isEmpty } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import defaultStairImage from '../../assets/images/stairs-default-image.png';
import {
  calculateNoOfSteps,
  calculateVolumeForStairs,
  sendAnalyticsData
} from '../../common/utils';
import DecimalNumberComponent from '../DecimalNumberComponent';
import NumberComponent from '../NumberComponent';

const Steps = ({ stepsConfig = null }) => {
  let imageUrl;
  if (stepsConfig?.picture?.length > 0) {
    if (stepsConfig?.picture?.[0]?.url) {
      imageUrl = stepsConfig?.picture?.[0]?.url;
    } else {
      imageUrl = URL?.createObjectURL(stepsConfig?.picture?.[0]?.originFileObj);
    }
  } else {
    imageUrl = stepsConfig?.picture?.url;
  }

  return (
    <>
      {stepsConfig?.pictureCheck && (
        <div className="stairs-image-section">
          <img src={imageUrl || defaultStairImage} alt="stairs-preview" />
        </div>
      )}

      <Row>
        <Col span={22}>
          <Form.Item
            name={['dimension', 'stairs', 'steps', 'stepsWidth']}
            rules={[
              {
                required: true,
                message: 'Please Enter Steps Width.'
              }
            ]}
          >
            <DecimalNumberComponent
              className="dimension-decimal-input"
              placeholder={stepsConfig?.width}
              decimalValue={2}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <div className="measurement-span">ft</div>
        </Col>
      </Row>
      <Row>
        <Col span={22}>
          <Form.Item
            name={['dimension', 'stairs', 'steps', 'stepsDepth']}
            rules={[
              {
                required: true,
                message: 'Please Enter Steps Depth.'
              }
            ]}
          >
            <DecimalNumberComponent
              className="dimension-decimal-input"
              placeholder={stepsConfig?.depth}
              decimalValue={2}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <div className="measurement-span">ft</div>
        </Col>
      </Row>
      <Row>
        <Col span={22}>
          <div className="custom-dimension-suffix">
            <Form.Item
              name={['dimension', 'stairs', 'steps', 'stepsHeight']}
              rules={[
                {
                  required: true,
                  message: 'Please Enter Steps Height.'
                }
              ]}
            >
              <DecimalNumberComponent
                className="dimension-decimal-input"
                placeholder={stepsConfig?.height}
                decimalValue={2}
              />
            </Form.Item>
          </div>
        </Col>
        <Col span={2}>
          <div className="measurement-span">ft</div>
        </Col>
      </Row>
    </>
  );
};

const TotalRise = ({ totalRiseConfig = null }) => {
  let imageUrl;
  if (totalRiseConfig?.image?.length > 0) {
    if (totalRiseConfig?.image?.[0]?.url) {
      imageUrl = totalRiseConfig?.image?.[0]?.url;
    } else {
      imageUrl = URL?.createObjectURL(
        totalRiseConfig?.image?.[0]?.originFileObj
      );
    }
  } else {
    imageUrl = totalRiseConfig?.image?.url;
  }

  return (
    <>
      {totalRiseConfig?.imageCheck && (
        <div className="stairs-image-section">
          <img src={imageUrl || defaultStairImage} alt="stairs-preview" />
        </div>
      )}
      {totalRiseConfig?.additionalOptionCheck && (
        <div className="fill-width d-flex justify-center">
          <Form.Item
            name={['dimension', 'stairs', 'totalRise', 'totalRiseFlushMount']}
            valuePropName="checked"
          >
            <Checkbox readOnly className="common-checkbox">
              {totalRiseConfig?.additionalOptionText}
            </Checkbox>
          </Form.Item>
        </div>
      )}
      <Row>
        <Col span={22}>
          <Form.Item
            name={['dimension', 'stairs', 'totalRise', 'stairsTotalHeight']}
            rules={[
              {
                required: true,
                message: 'Please Enter Steps Total Height.'
              }
            ]}
          >
            <DecimalNumberComponent
              className="dimension-decimal-input"
              placeholder={totalRiseConfig?.totalHeight}
              decimalValue={2}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <div className="measurement-span">ft</div>
        </Col>
      </Row>
      <Row>
        <Col span={22}>
          <Form.Item
            name={['dimension', 'stairs', 'totalRise', 'stairsTotalSteps']}
          >
            <NumberComponent
              readOnly
              placeholder={totalRiseConfig?.totalSteps}
              className="dimension-read-only"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={22}>
          <Form.Item
            name={['dimension', 'stairs', 'totalRise', 'stairsVolume']}
          >
            <DecimalNumberComponent
              className="readonly-dimension-decimal-input"
              placeholder={totalRiseConfig?.volume}
              readOnly
              decimalValue={2}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <div className="measurement-span">cu/yds</div>
        </Col>
      </Row>
    </>
  );
};

const StairsForm = ({ widgetConfig = null, form = null, setSavedData }) => {
  useEffect(() => {
    form?.setFieldsValue({
      additional: widgetConfig?.totalRise?.selectedCheck
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetConfig]);
  const {
    state: { storedData },
    dispatch
  } = useContext(AppContext);

  const data = { ...storedData };
  const [selectedTab, setSelectedTab] = useState();
  const widthValue = Form?.useWatch(
    ['dimension', 'stairs', 'steps', 'stepsWidth'],
    form
  );
  const depthValue = Form?.useWatch(
    ['dimension', 'stairs', 'steps', 'stepsDepth'],
    form
  );
  const heightValue = Form?.useWatch(
    ['dimension', 'stairs', 'steps', 'stepsHeight'],
    form
  );
  const totalHeightValue = Form?.useWatch(
    ['dimension', 'stairs', 'totalRise', 'stairsTotalHeight'],
    form
  );
  const flushMountValue = Form?.useWatch(
    ['dimension', 'stairs', 'totalRise', 'totalRiseFlushMount'],
    form
  );
  const totalStepsValue = Form?.useWatch(
    ['dimension', 'stairs', 'totalRise', 'stairsTotalSteps'],
    form
  );
  const volumeValue = Form?.useWatch(
    ['dimension', 'stairs', 'totalRise', 'stairsVolume'],
    form
  );

  const [totalSteps, setTotalSteps] = useState(0);

  useEffect(() => {
    if (widgetConfig?.steps?.defaultCheck) {
      setSelectedTab('STEPS');
    }
    if (widgetConfig?.totalRise?.defaultCheck) {
      setSelectedTab('TOTAL_RISE');
    }
  }, [widgetConfig]);

  useEffect(() => {
    if (heightValue && totalHeightValue) {
      const noOfSteps = calculateNoOfSteps(totalHeightValue, heightValue);
      setTotalSteps(noOfSteps);
      form?.setFieldValue(
        ['dimension', 'stairs', 'totalRise', 'stairsTotalSteps'],
        noOfSteps
      );
    } else {
      form?.setFieldValue(
        ['dimension', 'stairs', 'totalRise', 'stairsTotalSteps'],
        undefined
      );
    }
    if (!isEmpty(data?.dimension)) {
      dispatch({
        type: 'SET_SHOW_SUBMIT_BTN',
        data: true
      });
      return;
    }

    if (widthValue && depthValue && heightValue && totalHeightValue) {
      dispatch({
        type: 'SET_SHOW_SUBMIT_BTN',
        data: true
      });
    } else {
      dispatch({
        type: 'SET_SHOW_SUBMIT_BTN',
        data: false
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widthValue, depthValue, heightValue, totalHeightValue]);

  useEffect(() => {
    if (widthValue && depthValue && totalSteps) {
      const volume = calculateVolumeForStairs(
        widthValue,
        heightValue,
        depthValue,
        totalSteps
      );

      form?.setFieldValue(
        ['dimension', 'stairs', 'totalRise', 'stairsVolume'],
        volume
      );

      // dynamic Unit of measure changes
      // STEPS DIMENSION FILTERS
      const depthKey = widgetConfig?.steps?.unitOfMeasureDepthKey;
      const heightKey = widgetConfig?.steps?.unitOfMeasureHeightKey;
      const widthKey = widgetConfig?.steps?.unitOfMeasureWidthKey;

      const defaultWidthUnit =
        widgetConfig?.steps?.unitOfMeasureWidthDefaultUnit;
      const defaultStepsHeightUnit =
        widgetConfig?.steps?.unitOfMeasureHeightDefaultUnit;
      const defaultDepthUnit =
        widgetConfig?.steps?.unitOfMeasureDepthDefaultUnit;

      // TOTAL RISE DIMENSION FILTERS
      const totalNOStepsKey =
        widgetConfig?.totalRise?.unitOfMeasureTotalNoOfStepsKey;
      const volumeKey = widgetConfig?.totalRise?.unitOfMeasureVolumeKey;
      const totalStepsHeightKey =
        widgetConfig?.totalRise?.unitOfMeasureHeightKey;

      const defaultTotalRiseHeightUnit =
        widgetConfig?.totalRise?.unitOfMeasureHeightDefaultUnit;
      const defaultUnitNoOfSteps =
        widgetConfig?.totalRise?.unitOfMeasureTotalNoOfStepsDefaultUnit;
      const defaultVolumeUnit =
        widgetConfig?.totalRise?.unitOfMeasureVolumeDefaultUnit;

      const dimensionFilter = [
        {
          key: depthKey,
          value: depthValue,
          unit: defaultDepthUnit
        },
        {
          key: heightKey,
          value: heightValue,
          unit: defaultStepsHeightUnit
        },
        {
          key: widthKey,
          value: widthValue,
          unit: defaultWidthUnit
        },
        {
          key: totalNOStepsKey,
          value: totalSteps,
          unit: defaultUnitNoOfSteps
        },
        {
          key: volumeKey,
          value: volume,
          unit: defaultVolumeUnit
        },
        {
          key: totalStepsHeightKey,
          value: totalHeightValue,
          unit: defaultTotalRiseHeightUnit
        }
      ];

      setSavedData((prev) => ({
        ...prev,
        dimensionFilter
      }));
    } else {
      form?.setFieldValue(
        ['dimension', 'stairs', 'totalRise', 'stairsVolume'],
        undefined
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widthValue, depthValue, totalSteps]);

  const items = [
    {
      key: 'STEPS',
      label: widgetConfig?.steps?.title,
      children: <Steps stepsConfig={widgetConfig?.steps} />
    },
    {
      key: 'TOTAL_RISE',
      label: widgetConfig?.totalRise?.title,
      children: (
        <TotalRise
          totalRiseConfig={widgetConfig?.totalRise}
          dimension={{ widthValue, heightValue, depthValue, totalSteps }}
        />
      )
    }
  ];

  // analytics flow
  useEffect(() => {
    sendAnalyticsData({
      stepsWidth: widthValue,
      stepsDepth: depthValue,
      stepsHeight: heightValue,
      stairsTotalHeight: totalHeightValue,
      totalRiseFlushMount: flushMountValue || false,
      stairsTotalSteps: totalStepsValue,
      stairsVolume: volumeValue,
      selectedTab: find(items, (item) => item?.key === selectedTab)?.label
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    widthValue,
    depthValue,
    heightValue,
    totalHeightValue,
    flushMountValue,
    totalStepsValue,
    volumeValue,
    selectedTab
  ]);

  const handleSelectTab = (key) => {
    setSelectedTab(key);
  };

  return (
    <Tabs
      activeKey={selectedTab}
      onChange={handleSelectTab}
      items={items}
      className="dimension-tabs"
      centered
    />
  );
};

export default StairsForm;
