import { Input } from 'antd';
import React from 'react';
import './styles/NumberComponent.less';

const NumberComponent = (props) => {
  const {
    name = '',
    className = '',
    min = 0,
    innerref = null,
    ...rest
  } = props;

  return (
    <Input
      className={`number-component ${className}`}
      type="number"
      allowClear
      placeholder={`Enter ${name || ''}`}
      min={min}
      ref={innerref}
      {...rest}
    />
  );
};

export default NumberComponent;
