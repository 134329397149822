import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement
} from '@stripe/react-stripe-js';
import { Col, Form, Row } from 'antd';
import React from 'react';
import './styles/CardSection.less';

const useOptions = (placeholder) => {
  const options = {
    placeholder,
    showIcon: true,
    style: {
      base: {
        textAlign: 'center',
        fontSize: '14px',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#a3a3ad'
        }
      },
      invalid: {
        color: '#de3838'
      }
    }
  };

  return options;
};

const CardSection = () => {
  return (
    <Row gutter={15}>
      <Col span={24}>
        <Form.Item
          rules={[
            () => ({
              validator(_, value) {
                if (value?.complete || typeof value?.error === 'undefined') {
                  return Promise?.resolve();
                }
                return Promise?.reject(value?.error?.message);
              }
            })
          ]}
          name="cardNumber"
        >
          <CardNumberElement options={useOptions('Card Number *')} />
        </Form.Item>
      </Col>
      <Col span={16}>
        <Form.Item
          rules={[
            () => ({
              validator(_, value) {
                if (value?.complete || typeof value?.error === 'undefined') {
                  return Promise?.resolve();
                }
                return Promise?.reject(value?.error?.message);
              }
            })
          ]}
          name="cardExpiry"
        >
          <CardExpiryElement options={useOptions('MM / YY')} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          rules={[
            () => ({
              validator(_, value) {
                if (value?.complete || typeof value?.error === 'undefined') {
                  return Promise?.resolve();
                }
                return Promise?.reject(value?.error?.message);
              }
            })
          ]}
          name="cardCvc"
        >
          <CardCvcElement options={useOptions('CVC *')} />
        </Form.Item>
      </Col>
    </Row>
  );
};
export default CardSection;
