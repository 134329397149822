import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import './styles/EditorComponent.less';

const EditorComponent = (props) => {
  const { setEditorValue, className = '', ...rest } = props;

  const [value, setValue] = useState('');

  const handleChange = (newValue) => {
    setValue(newValue);
    if (setEditorValue) {
      setEditorValue(newValue);
    }
  };

  return (
    <ReactQuill
      className={`editor-component ${className}`}
      onChange={handleChange}
      value={value}
      placeholder="Write text here"
      {...rest}
    />
  );
};

export default EditorComponent;
