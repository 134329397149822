import { InfoCircleOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { sendAnalyticsData } from '../../common/utils';
import DecimalNumberComponent from '../DecimalNumberComponent';

const RenderSystemNumber = ({
  widgetConfig = null,
  editData = null,
  form = null,
  fieldConfig = null
}) => {
  const fieldId = widgetConfig?.selectedSystemFieldBlock?.selectedSystemFieldId;
  const label = widgetConfig?.label;
  const required = widgetConfig?.required;
  const toolTipCheck = widgetConfig?.toolTipCheck;
  const toolTipText = widgetConfig?.toolTipText;
  const defaultValue = fieldConfig?.NUMBER?.default;

  const inputValue = Form?.useWatch(
    ['systemFields', fieldId, 'NUMBER', 'value'],
    form
  );

  // analytics flow
  useEffect(() => {
    sendAnalyticsData({ [label]: inputValue });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  useEffect(
    () => {
      if (!isEmpty(editData?.[fieldId]?.value)) {
        form?.setFieldsValue({
          systemFields: {
            [fieldId]: {
              NUMBER: {
                value: `${editData?.[fieldId]?.value?.[0]}`
              }
            }
          }
        });
      }
      if (defaultValue) {
        form?.setFieldsValue({
          systemFields: {
            [fieldId]: {
              NUMBER: {
                value: defaultValue
              }
            }
          }
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editData]
  );

  return (
    <div>
      <Form.Item
        name={['systemFields', fieldId, 'NUMBER', 'value']}
        label={label || ' '}
        tooltip={
          toolTipCheck && {
            title: toolTipText,
            icon: <InfoCircleOutlined />
          }
        }
        rules={[
          { required, message: 'This field is required. Please fill it in.' }
        ]}
        className={`${toolTipCheck && 'label-with-tooltip'}`}
      >
        <DecimalNumberComponent
          name="Number"
          placeholder={`Enter your ${label}`}
        />
      </Form.Item>
    </div>
  );
};

export default RenderSystemNumber;
