import { Modal } from 'antd';
import React from 'react';
import LoaderComponent from './LoaderComponent';
import './styles/ModalComponent.less';

const ModalComponent = (props) => {
  const {
    centered = true,
    maskClosable = false,
    loading,
    children,
    ...rest
  } = props;
  return (
    <Modal
      className="modal"
      centered={centered} // centered modal
      maskClosable={maskClosable} // whether to close the modal when clicked outside the modal
      {...rest}
    >
      {loading ? <LoaderComponent /> : <>{children}</>}
    </Modal>
  );
};

export default ModalComponent;
