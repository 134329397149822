import { DatePicker } from 'antd';
import moment from 'moment';
import React from 'react';
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATE_TIME_FORMAT
} from '../common/constants';
import './styles/DatePickerComponent.less';

const DatePickerComponent = (props) => {
  const {
    showTime = false,
    disabledDate = false,
    showToday = false,
    className = '',
    disablePastDate = true,
    disableFutureDate = false,
    dateFormat = DEFAULT_DATE_FORMAT,
    datePickerRef,
    dateTimeFormat = DEFAULT_DATE_TIME_FORMAT,
    isCurrentDateAllowed = false,
    ...rest
  } = props;

  const disabledDateFun = (current) => {
    if (disableFutureDate) {
      if (isCurrentDateAllowed) {
        return current && current > moment()?.endOf('day');
      }
      return current && current > moment()?.startOf('day');
    }
    if (disablePastDate) {
      // Can not select days before today
      if (isCurrentDateAllowed) {
        return current && current < moment()?.endOf('day');
      }
      return current && current < moment()?.startOf('day');
    }
  };

  return (
    <DatePicker
      className={`datepicker-component ${className}`}
      bordered
      format={showTime ? dateTimeFormat : dateFormat}
      showTime={showTime}
      showToday={showToday}
      disabledDate={disabledDate && disabledDateFun}
      ref={datePickerRef}
      {...rest}
    />
  );
};

export default DatePickerComponent;
