/* eslint-disable no-underscore-dangle */
/* eslint-disable block-scoped-var */
/* eslint-disable prefer-const */
/* eslint-disable prefer-destructuring */
/* eslint-disable prefer-spread */
/* eslint-disable no-shadow */
/* eslint-disable no-plusplus */
/* eslint-disable no-bitwise */
/* eslint-disable no-cond-assign */
/* eslint-disable no-var */
/* eslint-disable vars-on-top */
/* eslint-disable no-param-reassign */
/* eslint no-undef: "off" */

import { isMobileOnly, isTablet } from 'react-device-detect';

export default (function () {
  // eslint-disable-next-line no-lone-blocks
  {
    var unknown = '-';
    // screen
    var screenSize = '';
    var width;
    var height;
    if (window?.screen) {
      width = window?.screen?.width ? window?.screen?.width : '';
      height = window?.screen?.height ? window?.screen?.height : '';
      screenSize += `${width} x ${height}`;
    }

    // browser
    var nVer = navigator?.appVersion;
    var nAgt = navigator?.userAgent;
    var version = navigator?.appVersion;
    var browser = navigator?.appName;
    var majorVersion = parseInt(version, 10);
    var nameOffset;
    var verOffset;
    var ix;

    // Opera
    if ((verOffset = nAgt?.indexOf('Opera')) !== -1) {
      browser = 'Opera';
      version = nAgt?.substring(verOffset + 6);
      if ((verOffset = nAgt?.indexOf('Version')) !== -1) {
        version = nAgt?.substring(verOffset + 8);
      }
    }
    // Opera Next
    if ((verOffset = nAgt?.indexOf('OPR')) !== -1) {
      browser = 'Opera';
      version = nAgt?.substring(verOffset + 4);
    }
    // Edge
    else if ((verOffset = nAgt?.indexOf('Edg')) !== -1) {
      browser = 'Microsoft Edge';
      version = nAgt?.substring(verOffset + 5);
    }
    // MSIE
    else if ((verOffset = nAgt?.indexOf('MSIE')) !== -1) {
      browser = 'Microsoft Internet Explorer';
      version = nAgt?.substring(verOffset + 5);
    }
    // Chrome
    else if ((verOffset = nAgt?.indexOf('Chrome')) !== -1) {
      browser = 'Chrome';
      version = nAgt?.substring(verOffset + 7);
    }
    // Safari
    else if ((verOffset = nAgt?.indexOf('Safari')) !== -1) {
      browser = 'Safari';
      version = nAgt?.substring(verOffset + 7);
      if ((verOffset = nAgt?.indexOf('Version')) !== -1) {
        version = nAgt?.substring(verOffset + 8);
      }
    }
    // Firefox
    else if ((verOffset = nAgt?.indexOf('Firefox')) !== -1) {
      browser = 'Firefox';
      version = nAgt?.substring(verOffset + 8);
    }
    // MSIE 11+
    else if (nAgt?.indexOf('Trident/') !== -1) {
      browser = 'Microsoft Internet Explorer';
      version = nAgt?.substring(nAgt?.indexOf('rv:') + 3);
    }
    // Other browsers
    else if (
      (nameOffset = nAgt?.lastIndexOf(' ') + 1) <
      (verOffset = nAgt?.lastIndexOf('/'))
    ) {
      browser = nAgt?.substring(nameOffset, verOffset);
      version = nAgt?.substring(verOffset + 1);
      if (browser?.toLowerCase() === browser?.toUpperCase()) {
        browser = navigator?.appName;
      }
    }
    // trim the version string
    if ((ix = version?.indexOf(';')) !== -1)
      version = version?.substring(0, ix);
    if ((ix = version?.indexOf(' ')) !== -1)
      version = version?.substring(0, ix);
    if ((ix = version?.indexOf(')')) !== -1)
      version = version?.substring(0, ix);

    majorVersion = parseInt(`${version}`, 10);
    if (Number(majorVersion)) {
      version = `${parseFloat(navigator?.appVersion)}`;
      majorVersion = parseInt(navigator?.appVersion, 10);
    }

    // mobile version
    var mobile = /Mobile|mini|Fennec|Android|iP(ad|od|hone)/.test(nVer);

    // cookie
    var cookieEnabled = !!navigator?.cookieEnabled;

    if (typeof navigator?.cookieEnabled === 'undefined' && !cookieEnabled) {
      document.cookie = 'testcookie';
      cookieEnabled = document?.cookie?.indexOf('testcookie') !== -1;
    }

    // system
    var os = unknown;
    var clientStrings = [
      { s: 'Windows 10', r: /(Windows 10.0|Windows NT 10.0)/ },
      { s: 'Windows 11', r: /(Windows 11.0|Windows NT 11.0)/ },
      { s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/ },
      { s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/ },
      { s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/ },
      { s: 'Windows Vista', r: /Windows NT 6.0/ },
      { s: 'Windows Server 2003', r: /Windows NT 5.2/ },
      { s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/ },
      { s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/ },
      { s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/ },
      { s: 'Windows 98', r: /(Windows 98|Win98)/ },
      { s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/ },
      { s: 'Windows NT 4.0', r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
      { s: 'Windows CE', r: /Windows CE/ },
      { s: 'Windows 3.11', r: /Win16/ },
      { s: 'Android', r: /Android/ },
      { s: 'Open BSD', r: /OpenBSD/ },
      { s: 'Sun OS', r: /SunOS/ },
      { s: 'Linux', r: /(Linux|X11)/ },
      { s: 'iOS', r: /(iPhone|iPad|iPod)/ },
      { s: 'Mac OS X', r: /Mac OS X/ },
      { s: 'Mac OS', r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
      { s: 'QNX', r: /QNX/ },
      { s: 'UNIX', r: /UNIX/ },
      { s: 'BeOS', r: /BeOS/ },
      { s: 'OS/2', r: /OS\/2/ },
      {
        s: 'Search Bot',
        r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/
      }
    ];

    // eslint-disable-next-line no-restricted-syntax
    for (var id in clientStrings) {
      if (Object?.hasOwnProperty?.call(clientStrings, id)) {
        var cs = clientStrings?.[id];
        if (cs?.r?.test(nAgt)) {
          os = cs?.s;
          break;
        }
      }
    }

    var osVersion = unknown;

    if (/Windows/.test(os)) {
      osVersion = /Windows (.*)/.exec(os)[1];
      os = 'Windows';
    }

    switch (os) {
      case 'Mac OS X':
        // eslint-disable-next-line no-useless-escape
        osVersion = /Mac OS X (10[\.\_\d]+)/.exec(nAgt)[1];
        break;

      case 'Android':
        // eslint-disable-next-line no-useless-escape
        osVersion = /Android ([\.\_\d]+)/.exec(nAgt)[1];
        break;

      case 'iOS':
        osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
        osVersion = `${osVersion?.[1]}.${osVersion?.[2]}.${osVersion?.[3] | 0}`;
        break;

      default:
      // do nothing
    }

    // flash (you'll need to include swfobject)
    /* script src="//ajax.googleapis.com/ajax/libs/swfobject/2.2/swfobject.js" */
    var flashVersion = 'no check';
  }
  window.clientInfo = {
    screen: screenSize,
    browser,
    browserVersion: version,
    browserMajorVersion: majorVersion,
    mobile,
    os,
    osVersion,
    cookies: cookieEnabled,
    flashVersion
  };
})(this);
var JSON_WSM;
if (
  typeof JSON_WSM !== 'object' &&
  typeof window?.JSON === 'object' &&
  window?.JSON?.stringify &&
  window?.JSON?.parse
) {
  JSON_WSM = window?.JSON;
}

let _wsm = [];

if (typeof window?.Wsm !== 'object') {
  window.Wsm = (function () {
    var expireDateTime;
    var plugins = {};
    var winEncodeWrapper = window?.encodeURIComponent;
    var winDecodeWrapper = window?.decodeURIComponent;
    var arrTrackers = [];
    var arrTimeOuts = [];
    var iterator;
    var Wsm;
    function safeDecodeWrapper(url) {
      try {
        return winDecodeWrapper(url);
      } catch (e) {
        return unescape(url);
      }
    }
    function isDefined(property) {
      var propertyType = typeof property;
      return propertyType !== 'undefined';
    }
    function isFunction(property) {
      return typeof property === 'function';
    }
    function isObject(property) {
      return typeof property === 'object';
    }
    function isString(property) {
      return typeof property === 'string' || property instanceof String;
    }
    function logConsoleError(message) {
      // eslint-disable-next-line no-console
      if (console !== undefined && console && console?.error) {
        // eslint-disable-next-line no-console
        console?.error(message);
      }
    }
    function apply() {
      var i;
      var j;
      var f;
      var parameterArray;
      for (i = 0; i < arguments?.length; i += 1) {
        trackerCall = null;
        // eslint-disable-next-line prefer-rest-params
        parameterArray = arguments?.[i];
        f = parameterArray?.shift();
        var fParts;
        var context;
        if (arrTrackers?.length > 0) {
          for (j = 0; j < arrTrackers?.length; j++) {
            if (isString(f)) {
              context = arrTrackers?.[j];

              var isPluginTrackerCall = f?.indexOf('.') > 0;

              if (isPluginTrackerCall) {
                fParts = f?.split('.');
                if (context && typeof context?.[fParts?.[0]] === 'object') {
                  context = context?.[fParts?.[0]];
                  f = fParts?.[1];
                }
              }

              if (context?.[f]) {
                context?.[f]?.apply(context, parameterArray);
              } else {
                var message = `The method '${f}' was not found in "_wsm" variable.`;
                logConsoleError(message);

                if (!isPluginTrackerCall) {
                  // do not trigger an error if it is a call to a plugin as the plugin may just not be
                  // loaded yet etc
                  throw new TypeError(message);
                }
              }

              if (f === 'newTracker') {
                break;
              }

              if (f === 'setTrackerUrl' || f === 'setTenantId') {
                // these two methods should be only executed on the first tracker
                break;
              }
            } else {
              f?.apply(arrTrackers?.[j], parameterArray);
            }
          }
        }
      }
    }
    function addEventListener(element, eventType, eventHandler, useCapture) {
      if (element?.addEventListener) {
        element?.addEventListener(eventType, eventHandler, useCapture);

        return true;
      }

      if (element?.attachEvent) {
        return element?.attachEvent(`on${eventType}`, eventHandler);
      }

      element[`on${eventType}`] = eventHandler;
    }
    function trackCallbackOnLoad(callback) {
      if (document?.readyState === 'complete') {
        callback();
      } else if (window?.addEventListener) {
        window?.addEventListener('load', callback);
      } else if (window?.attachEvent) {
        window?.attachEvent('onload', callback);
      }
    }
    function trackCallbackOnReady(callback) {
      var loaded = false;
      if (document?.attachEvent) {
        loaded = document?.readyState === 'complete';
      } else {
        loaded = document?.readyState !== 'loading';
      }

      if (loaded) {
        callback();
        return;
      }
      // var _timer;
      if (document?.addEventListener) {
        addEventListener(document, 'DOMContentLoaded', function ready() {
          document?.removeEventListener('DOMContentLoaded', ready, false);
          if (!loaded) {
            loaded = true;
            callback();
          }
        });
      } else if (document?.attachEvent) {
        document?.attachEvent('onreadystatechange', function ready() {
          if (document?.readyState === 'complete') {
            document?.detachEvent('onreadystatechange', ready);
            if (!loaded) {
              loaded = true;
              callback();
            }
          }
        });

        if (document?.documentElement?.doScroll && window === window?.top) {
          (function ready() {
            if (!loaded) {
              try {
                document?.documentElement?.doScroll('left');
              } catch (error) {
                setTimeout(ready, 0);

                return;
              }
              loaded = true;
              callback();
            }
          })();
        }
      }

      // fallback
      addEventListener(
        window,
        'load',
        function () {
          if (!loaded) {
            loaded = true;
            callback();
          }
        },
        false
      );
    }
    function executePluginMethod(methodName, callback) {
      var result = '';
      var i;
      var pluginMethod;
      var value;

      // eslint-disable-next-line no-restricted-syntax
      for (i in plugins) {
        if (Object?.prototype?.hasOwnProperty?.call(plugins, i)) {
          pluginMethod = plugins?.[i]?.[methodName];

          if (isFunction(pluginMethod)) {
            value = pluginMethod(callback);
            if (value) {
              result += value;
            }
          }
        }
      }

      return result;
    }
    // function beforeUnloadHandler() {
    //   var now;
    //   executePluginMethod('unload');
    //   if (expireDateTime) {
    //     do {
    //       now = new Date();
    //     } while (now.getTimeAlias() < expireDateTime);
    //   }
    // }
    function getReferrer() {
      var referrer = '';

      try {
        referrer = window?.top?.document?.referrer;
      } catch (e) {
        if (window?.parent) {
          try {
            referrer = window?.parent?.document?.referrer;
          } catch (e2) {
            referrer = '';
          }
        }
      }

      if (referrer === '') {
        referrer = document?.referrer;
      }

      return referrer;
    }
    // function getProtocolScheme(url) {
    //   var e = new RegExp('^([a-z]+):');
    //   var matches = e.exec(url);

    //   return matches ? matches[1] : null;
    // }
    function getHostName(url) {
      // scheme : // [username [: password] @] hostame [: port] [/ [path] [? query] [# fragment]]
      var e = new RegExp('^(?:(?:https?|ftp):)/*(?:[^@]+@)?([^:/#]+)');
      var matches = e?.exec(url);

      return matches ? matches?.[1] : url;
    }
    function getParameter(url, name) {
      var regexSearch = `[\\?&#]${name}=([^&#]*)`;
      var regex = new RegExp(regexSearch);
      var results = regex?.exec(url);
      return results ? winDecodeWrapper(results?.[1]) : '';
    }
    function utf8Encode(argString) {
      return unescape(winEncodeWrapper(argString));
    }
    function sha1(str) {
      // +   original by: Webtoolkit.info (http://www.webtoolkit.info/)
      // + namespaced by: Michael White (http://getsprink.com)
      // +      input by: Brett Zamir (http://brett-zamir.me)
      // +   improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
      // +   jslinted by: Anthon Pang (http://wsm.org)
      var rotateLeft = function (n, s) {
        return (n << s) | (n >>> (32 - s));
      };
      var cvtHex = function (val) {
        var strout = '';
        var i;
        var v;

        for (i = 7; i >= 0; i--) {
          v = (val >>> (i * 4)) & 0x0f;
          strout += v?.toString(16);
        }

        return strout;
      };
      var blockstart;
      var i;
      var j;
      var W = [];
      var H0 = 0x67452301;
      var H1 = 0xefcdab89;
      var H2 = 0x98badcfe;
      var H3 = 0x10325476;
      var H4 = 0xc3d2e1f0;
      var A;
      var B;
      var C;
      var D;
      var E;
      var temp;
      var strLen;
      var wordArray = [];

      str = utf8Encode(str);
      strLen = str?.length;

      for (i = 0; i < strLen - 3; i += 4) {
        j =
          (str?.charCodeAt(i) << 24) |
          (str?.charCodeAt(i + 1) << 16) |
          (str?.charCodeAt(i + 2) << 8) |
          str?.charCodeAt(i + 3);
        wordArray?.push(j);
      }

      switch (strLen & 3) {
        default:
        case 0:
          i = 0x080000000;
          break;
        case 1:
          i = (str?.charCodeAt(strLen - 1) << 24) | 0x0800000;
          break;
        case 2:
          i =
            (str?.charCodeAt(strLen - 2) << 24) |
            (str?.charCodeAt(strLen - 1) << 16) |
            0x08000;
          break;
        case 3:
          i =
            (str?.charCodeAt(strLen - 3) << 24) |
            (str?.charCodeAt(strLen - 2) << 16) |
            (str?.charCodeAt(strLen - 1) << 8) |
            0x80;
          break;
      }

      wordArray?.push(i);

      while ((wordArray?.length & 15) !== 14) {
        wordArray?.push(0);
      }

      wordArray?.push(strLen >>> 29);
      wordArray?.push((strLen << 3) & 0x0ffffffff);

      for (blockstart = 0; blockstart < wordArray?.length; blockstart += 16) {
        for (i = 0; i < 16; i++) {
          W[i] = wordArray?.[blockstart + i];
        }

        for (i = 16; i <= 79; i++) {
          W[i] = rotateLeft(W[i - 3] ^ W[i - 8] ^ W[i - 14] ^ W[i - 16], 1);
        }

        A = H0;
        B = H1;
        C = H2;
        D = H3;
        E = H4;

        for (i = 0; i <= 19; i++) {
          temp =
            (rotateLeft(A, 5) + ((B & C) | (~B & D)) + E + W[i] + 0x5a827999) &
            0x0ffffffff;
          E = D;
          D = C;
          C = rotateLeft(B, 30);
          B = A;
          A = temp;
        }

        for (i = 20; i <= 39; i++) {
          temp =
            (rotateLeft(A, 5) + (B ^ C ^ D) + E + W[i] + 0x6ed9eba1) &
            0x0ffffffff;
          E = D;
          D = C;
          C = rotateLeft(B, 30);
          B = A;
          A = temp;
        }

        for (i = 40; i <= 59; i++) {
          temp =
            (rotateLeft(A, 5) +
              ((B & C) | (B & D) | (C & D)) +
              E +
              W[i] +
              0x8f1bbcdc) &
            0x0ffffffff;
          E = D;
          D = C;
          C = rotateLeft(B, 30);
          B = A;
          A = temp;
        }

        for (i = 60; i <= 79; i++) {
          temp =
            (rotateLeft(A, 5) + (B ^ C ^ D) + E + W[i] + 0xca62c1d6) &
            0x0ffffffff;
          E = D;
          D = C;
          C = rotateLeft(B, 30);
          B = A;
          A = temp;
        }

        H0 = (H0 + A) & 0x0ffffffff;
        H1 = (H1 + B) & 0x0ffffffff;
        H2 = (H2 + C) & 0x0ffffffff;
        H3 = (H3 + D) & 0x0ffffffff;
        H4 = (H4 + E) & 0x0ffffffff;
      }

      temp = cvtHex(H0) + cvtHex(H1) + cvtHex(H2) + cvtHex(H3) + cvtHex(H4);

      return temp?.toLowerCase();
    }
    function urlFixup(hostName, href, referrer) {
      if (!hostName) {
        hostName = '';
      }

      if (!href) {
        href = '';
      }

      if (hostName === 'translate.googleusercontent.com') {
        // Google
        if (referrer === '') {
          referrer = href;
        }

        href = getParameter(href, 'u');
        hostName = getHostName(href);
      } else if (
        hostName === 'cc.bingj.com' || // Bing
        hostName === 'webcache.googleusercontent.com' || // Google
        hostName.slice(0, 5) === '74.6.'
      ) {
        // Yahoo (via Inktomi 74.6.0.0/16)
        href = document?.links?.[0]?.href;
        hostName = getHostName(href);
      }

      return [hostName, href, referrer];
    }
    function domainFixup(domain) {
      var dl = domain?.length;

      // remove trailing '.'
      if (domain?.charAt(--dl) === '.') {
        domain = domain?.slice(0, dl);
      }

      // remove leading '*'
      if (domain?.slice(0, 2) === '*.') {
        domain = domain?.slice(1);
      }

      if (domain?.indexOf('/') !== -1) {
        domain = domain?.substr(0, domain?.indexOf('/'));
      }

      return domain;
    }
    function titleFixup(title) {
      title = title && title?.text ? title?.text : title;
      if (!isString(title)) {
        var tmp = document?.getElementsByTagName('title');

        if (tmp && isDefined(tmp?.[0])) {
          title = tmp?.[0]?.text;
        }
      }

      return title;
    }
    function indexOfArray(theArray, searchElement) {
      if (theArray && theArray?.indexOf) {
        return theArray?.indexOf(searchElement);
      }
      if (!isDefined(theArray) || theArray === null) {
        return -1;
      }
      if (!theArray?.length) {
        return -1;
      }
      var len = theArray?.length;
      if (len === 0) {
        return -1;
      }
      var k = 0;
      while (k < len) {
        if (theArray[k] === searchElement) {
          return k;
        }
        k++;
      }
      return -1;
    }
    var wsmQuery = {
      isLinkElement(node) {
        if (!node) {
          return false;
        }

        var elementName = String(node?.nodeName)?.toLowerCase();
        var linkElementNames = ['a', 'area'];
        var pos = indexOfArray(linkElementNames, elementName);

        return pos !== -1;
      },
      hasNodeAttribute(node, attributeName) {
        if (node && node?.hasAttribute) {
          return node?.hasAttribute(attributeName);
        }

        if (node && node?.attributes) {
          var typeOfAttr = typeof node?.attributes[attributeName];
          return typeOfAttr !== 'undefined';
        }

        return false;
      },
      getAttributeValueFromNode(node, attributeName) {
        if (!this?.hasNodeAttribute(node, attributeName)) {
          return;
        }

        if (node && node?.getAttribute) {
          return node?.getAttribute(attributeName);
        }

        if (!node || !node?.attributes) {
          return;
        }

        var typeOfAttr = typeof node?.attributes[attributeName];
        if (typeOfAttr === 'undefined') {
          return;
        }

        if (node?.attributes[attributeName]?.value) {
          return node?.attributes[attributeName]?.value; // nodeValue is deprecated ie Chrome
        }

        if (node?.attributes[attributeName]?.nodeValue) {
          return node?.attributes[attributeName]?.nodeValue;
        }

        var index;
        var attrs = node?.attributes;

        if (!attrs) {
          return;
        }

        for (index = 0; index < attrs?.length; index++) {
          if (attrs[index]?.nodeName === attributeName) {
            return attrs[index]?.nodeValue;
          }
        }

        return null;
      }
    };
    function isInsideAnIframe() {
      var frameElement;

      try {
        // If the parent window has another origin, then accessing frameElement
        // throws an Error in IE. see issue #10105.
        frameElement = window?.frameElement;
      } catch (e) {
        // When there was an Error, then we know we are inside an iframe.
        return true;
      }

      if (isDefined(frameElement)) {
        return !!(
          frameElement &&
          String(frameElement?.nodeName)?.toLowerCase() === 'iframe'
        );
      }

      try {
        return window?.self !== window?.top;
      } catch (e2) {
        return true;
      }
    }
    function WsmTracker(trackerUrl, tenantId) {
      var locationArray = urlFixup(
        document?.domain,
        window?.location?.href,
        getReferrer()
      );
      var registeredHooks = {};
      var domainAlias = domainFixup(locationArray?.[0]);
      // var locationHrefAlias = safeDecodeWrapper(locationArray?.[1]);
      var configReferrerUrl = safeDecodeWrapper(locationArray?.[2]);
      var defaultRequestMethod = 'POST';
      var configRequestMethod = defaultRequestMethod;
      var defaultRequestContentType =
        // 'application/x-www-form-urlencoded; charset=UTF-8';
        'application/json';
      var configRequestContentType = defaultRequestContentType;
      var configTrackerUrl =
        trackerUrl || process?.env?.REACT_APP_ANALYTICS_URL;
      var configAppendToTrackingUrl = {};
      var configTrackerTenantId = tenantId || '';
      var configUserId = '';
      var wpUrlReferrer = '';
      var configPageId = '';
      var visitorUUID = '';
      var configTitle = '';
      var configHostsAlias = [domainAlias];
      var configTrackerPause = 500;
      var configHeartBeatDelay;
      var heartBeatPingIfActivityAlias;
      var configDiscardHashTag;
      var configCookieNamePrefix = '_wsm_';
      var configCookieDomain;
      var configCookiePath;
      var configCookiesDisabled = false;
      var configDoNotTrack;
      var configCountPreRendered;
      var configConversionAttributionFirstReferrer;
      var configVisitorCookieTimeout = 33955200000; // 13 months (365 days + 28days)
      var configSessionCookieTimeout = 1800000; // 30 minutes
      var configReferralCookieTimeout = 15768000000; // 6 months
      var browserFeatures = {};
      var nextTrackingRequestTime = false;
      var linkTrackingInstalled = false;

      var heartBeatSetUp = false;
      var hadWindowFocusAtLeastOnce = isInsideAnIframe();
      var lastTrackerRequestTime = null;
      var heartBeatTimeout;
      var lastButton;
      var lastTarget;
      var hash = sha1;
      var domainHash;

      var configIdPageView;
      var zipCode = null;

      var slug = '';
      var subDomain = '';
      var geoLocation = null;
      let customVisitorId = '';
      var selectedSubAreaId = '';
      var selectedLineOfBusinessKey = '';
      var analyticsData = null;
      var roqPathId = null;
      var isSubmit = false;
      var isBackPing = false;
      try {
        configTitle = document?.title;
      } catch (e) {
        configTitle = '';
      }
      if (navigator?.geolocation) {
        navigator?.geolocation?.watchPosition(({ coords }) => {
          geoLocation = [
            coords?.latitude?.toString(),
            coords?.longitude?.toString()
          ];
        });
      }
      function setCookie(cookieName, value, msToExpire, path, domain) {
        if (configCookiesDisabled) {
          return;
        }

        var expiryDate;

        // relative time to expire in milliseconds
        if (msToExpire) {
          expiryDate = new Date();
          expiryDate?.setTime(expiryDate?.getTime() + msToExpire);
        }

        document.cookie =
          `${cookieName}=` +
          `${winEncodeWrapper(value)}` +
          `; expires=` +
          `${expiryDate?.toUTCString() || ''}` +
          `; path=` +
          `/${path}` +
          `; domain=` +
          `${domain || ''}` +
          `; SameSite=None` +
          `; Secure`;
      }
      function getCookie(cookieName) {
        if (configCookiesDisabled) {
          return 0;
        }

        var cookiePattern = new RegExp(`(^|;)[ ]*${cookieName}=([^;]*)`);
        var cookieMatch = cookiePattern?.exec(document?.cookie);

        return cookieMatch ? winDecodeWrapper(cookieMatch[2]) : 0;
      }
      function purify(url) {
        var targetPattern;

        if (configDiscardHashTag) {
          targetPattern = new RegExp('#.*');

          return url?.replace(targetPattern, '');
        }

        return url;
      }

      function isSameHost(hostName, alias) {
        var offset;

        hostName = String(hostName)?.toLowerCase();
        alias = String(alias)?.toLowerCase();

        if (hostName === alias) {
          return true;
        }

        if (alias.slice(0, 1) === '.') {
          if (hostName === alias?.slice(1)) {
            return true;
          }

          offset = hostName?.length - alias?.length;

          if (offset > 0 && hostName?.slice(offset) === alias) {
            return true;
          }
        }

        return false;
      }
      function getLocation() {
        var locationAlias = this?.location || window?.location;

        if (!locationAlias?.origin) {
          locationAlias.origin = `${locationAlias?.protocol}//${
            locationAlias?.hostname
          }${locationAlias?.port ? `:${locationAlias?.port}` : ''}`;
        }

        return locationAlias;
      }
      function toAbsoluteUrl(url) {
        if ((!url || String(url) !== url) && url !== '') {
          // we only handle strings
          return url;
        }

        if (url === '') {
          return getLocation()?.href;
        }

        // Eg //example.com/test.jpg
        if (url?.search(/^\/\//) !== -1) {
          return getLocation()?.protocol + url;
        }

        // Eg http://example.com/test.jpg
        if (url?.search(/:\/\//) !== -1) {
          return url;
        }

        // Eg #test.jpg
        if (url?.indexOf('#') === 0) {
          return getLocation()?.origin + this?.getLocation()?.pathname + url;
        }

        // Eg ?x=5
        if (url?.indexOf('?') === 0) {
          return getLocation()?.origin + this?.getLocation()?.pathname + url;
        }

        // Eg mailto:x@y.z tel:012345, ... market:... sms:..., javasript:... ecmascript: ... and many more
        if (url?.search('^[a-zA-Z]{2,11}:') === 0) {
          return url;
        }

        // Eg /test.jpg
        if (url?.search(/^\//) !== -1) {
          return getLocation()?.origin + url;
        }

        // Eg test.jpg
        var regexMatchDir = '(.*/)';
        var base =
          getLocation()?.origin +
          getLocation()?.pathname?.match(new RegExp(regexMatchDir))?.[0];
        return base + url;
      }
      function getPathName(url) {
        var parser = document?.createElement('a');
        if (url?.indexOf('//') !== 0 && url?.indexOf('http') !== 0) {
          if (url?.indexOf('*') === 0) {
            url = url?.substr(1);
          }
          if (url?.indexOf('.') === 0) {
            url = url?.substr(1);
          }
          url = `http://${url}`;
        }
        parser.href = toAbsoluteUrl(url);
        if (parser?.pathname) {
          return parser?.pathname;
        }
        return '';
      }
      function isSiteHostName(hostName) {
        var i;
        var alias;
        var offset;

        for (i = 0; i < configHostsAlias?.length; i++) {
          alias = domainFixup(configHostsAlias[i]?.toLowerCase());

          if (hostName === alias) {
            return true;
          }

          if (alias.slice(0, 1) === '.') {
            if (hostName === alias?.slice(1)) {
              return true;
            }

            offset = hostName?.length - alias?.length;

            if (offset > 0 && hostName?.slice(offset) === alias) {
              return true;
            }
          }
        }

        return false;
      }
      function getImageRequest(request, callback) {
        var image = new Image(1, 1);

        image.onload = function () {
          iterator = 0; // To avoid JSLint warning of empty block
          if (typeof callback === 'function') {
            callback();
          }
        };
        // make sure to actually load an image so callback gets invoked
        request = request?.replace('send_image=0', 'send_image=1');
        // console.log(
        //   `My IMAGE Test :${configTrackerUrl}${
        //     configTrackerUrl.indexOf('?') < 0 ? '?' : '&'
        //   }${request}`
        // );
        image.src =
          configTrackerUrl +
          (configTrackerUrl?.indexOf('?') < 0 ? '?' : '&') +
          request;
      }
      function sendXmlHttpRequest(request, callback, fallbackToGet) {
        if (!isDefined(fallbackToGet) || fallbackToGet === null) {
          fallbackToGet = true;
        }

        try {
          // we use the progid Microsoft.XMLHTTP because
          // IE5.5 included MSXML 2.5; the progid MSXML2.XMLHTTP
          // is pinned to MSXML2.XMLHTTP.3.0
          var xhr = window?.XMLHttpRequest ? new window.XMLHttpRequest() : null;

          xhr?.open('POST', configTrackerUrl, true);

          xhr?.setRequestHeader('Content-Type', configRequestContentType);
          // console.log(
          //   `My XHR Test :${configTrackerUrl}${
          //     configTrackerUrl.indexOf('?') < 0 ? '?' : '&'
          //   }${request}`
          // );

          xhr?.send(
            JSON?.stringify({
              query: `mutation logVisit($data: VisitDataInput!) {
                logVisit(data: $data) {
                  status
                  message
                }
              }
            `,
              variables: {
                data: {
                  questionnaire: {
                    slug,
                    subDomain
                  },
                  ...(geoLocation?.length > 0 && {
                    geoLocation
                  }),
                  ...(selectedSubAreaId && { selectedSubAreaId }),
                  ...(selectedLineOfBusinessKey && {
                    selectedLineOfBusinessKey
                  }),
                  input: analyticsData,
                  roqPathId,
                  isSubmit,
                  ...request,
                  ping: request?.ping || isBackPing
                }
              }
            })
          );
          isSubmit = false;
          isBackPing = false;
        } catch (e) {
          if (fallbackToGet) {
            // fallback
            getImageRequest(request, callback);
          }
          isSubmit = false;
        }
      }
      function setExpireDateTime(delay) {
        var now = new Date();
        var time = now?.getTime() + delay;

        if (!expireDateTime || time > expireDateTime) {
          expireDateTime = time;
        }
      }
      function heartBeatUp(delay) {
        if (heartBeatTimeout || !configHeartBeatDelay) {
          return;
        }

        heartBeatTimeout = setTimeout(function heartBeat() {
          heartBeatTimeout = null;

          if (!hadWindowFocusAtLeastOnce) {
            // if browser does not support .hasFocus (eg IE5), we assume that the window has focus.
            hadWindowFocusAtLeastOnce =
              !document?.hasFocus || document?.hasFocus();
          }

          if (!hadWindowFocusAtLeastOnce) {
            // only send a ping if the tab actually had focus at least once. For example do not send a ping
            // if window was opened via "right click => open in new window" and never had focus see #9504
            heartBeatUp(configHeartBeatDelay);
            return;
          }

          if (heartBeatPingIfActivityAlias()) {
            return;
          }

          var now = new Date();
          var heartBeatDelay =
            configHeartBeatDelay - (now?.getTime() - lastTrackerRequestTime);
          // sanity check
          heartBeatDelay = Math?.min(configHeartBeatDelay, heartBeatDelay);
          heartBeatUp(heartBeatDelay);
        }, delay || configHeartBeatDelay);
        arrTimeOuts?.push(heartBeatTimeout);
      }
      function heartBeatDown() {
        clearTimeout(heartBeatTimeout);
        heartBeatTimeout = null;
      }
      function heartBeatOnFocus() {
        hadWindowFocusAtLeastOnce = true;

        // since it's possible for a user to come back to a tab after several hours or more, we try to send
        // a ping if the page is active. (after the ping is sent, the heart beat timeout will be set)
        if (heartBeatPingIfActivityAlias()) {
          return;
        }

        heartBeatUp();
      }
      function heartBeatOnBlur() {
        heartBeatDown();
      }
      function setUpHeartBeat() {
        if (heartBeatSetUp || !configHeartBeatDelay) {
          return;
        }

        heartBeatSetUp = true;

        addEventListener(window, 'focus', heartBeatOnFocus);
        addEventListener(window, 'blur', heartBeatOnBlur);

        heartBeatUp();
      }
      function checkGapAfterFirstRequest(callback) {
        var now = new Date();
        var timeNow = now?.getTime();

        lastTrackerRequestTime = timeNow;

        if (nextTrackingRequestTime && timeNow < nextTrackingRequestTime) {
          var timeToWait = nextTrackingRequestTime - timeNow;

          setTimeout(callback, timeToWait);
          setExpireDateTime(timeToWait + 50); // set timeout is not necessarily executed at timeToWait so delay a bit more
          nextTrackingRequestTime += 50; // delay next tracking request by further 50ms to next execute them at same time

          return;
        }

        if (nextTrackingRequestTime === false) {
          // it is the first request, we want to execute this one directly and delay all the next one(s) within a delay.
          // All requests after this delay can be executed as usual again
          var delayInMs = 800;
          nextTrackingRequestTime = timeNow + delayInMs;
        }

        callback();
      }
      function wsmSendRequest(request, delay, callback) {
        if (!configDoNotTrack && request) {
          checkGapAfterFirstRequest(function () {
            if (configRequestMethod === 'POST') {
              sendXmlHttpRequest(request, callback);
            } else {
              getImageRequest(request, callback);
            }

            setExpireDateTime(delay);
          });
        }

        if (!heartBeatSetUp) {
          setUpHeartBeat(); // setup window events too, but only once
        } else {
          heartBeatUp();
        }
      }
      function getCookieName(baseName) {
        return `${
          configCookieNamePrefix + baseName
        }_${configTrackerTenantId}_${domainHash}`;
      }
      function hasCookies() {
        if (configCookiesDisabled) {
          return '0';
        }

        if (!isDefined(navigator?.cookieEnabled)) {
          var testCookieName = getCookieName('testcookie');
          setCookie(testCookieName, '1');

          return getCookie(testCookieName) === '1' ? '1' : '0';
        }

        return navigator?.cookieEnabled ? '1' : '0';
      }
      function updateDomainHash() {
        domainHash = hash(
          (configCookieDomain || domainAlias) + (configCookiePath || '/')
        ).slice(0, 4); // 4 hexits = 16 bits
      }
      function generateRandomUuid() {
        var str =
          (navigator?.userAgent || '') +
          (navigator?.platform || '') +
          new Date()?.getTime() +
          Math?.random();
        var hStr = hash(str)?.slice(0, 16);
        return hStr;
      }
      function loadVisitorIdCookie() {
        var now = new Date();
        var nowTs = Math?.round(now?.getTime() / 1000);
        var visitorIdCookieName = getCookieName('id');
        var id = getCookie(visitorIdCookieName);
        var cookieValue;
        var uuid;

        // Visitor ID cookie found
        if (id) {
          cookieValue = id?.split('.');

          // returning visitor flag
          cookieValue?.unshift('0');

          if (visitorUUID?.length) {
            cookieValue[1] = visitorUUID;
          }
          return cookieValue;
        }

        if (visitorUUID?.length) {
          uuid = visitorUUID;
        } else if (hasCookies() === '0') {
          uuid = '';
        } else {
          // console.log('visitorUUID Test='+visitorUUID.length);
          uuid = generateRandomUuid();
          // console.log('visitorUUID Test='+uuid);
        }
        // No visitor ID cookie, let's create a new one
        cookieValue = [
          // new visitor
          '1',

          // uuid
          uuid,

          // creation timestamp - seconds since Unix epoch
          nowTs,

          // visitCount - 0 = no previous visit
          0,

          // current visit timestamp
          nowTs,

          // last visit timestamp - blank = no previous visit
          ''
        ];
        return cookieValue;
      }
      /**
       * Loads the Visitor ID cookie and returns a named array of values
       */
      function getValuesFromVisitorIdCookie() {
        var cookieVisitorIdValue = loadVisitorIdCookie();
        var newVisitor = cookieVisitorIdValue?.[0];
        var uuid = cookieVisitorIdValue?.[1];
        var createTs = cookieVisitorIdValue?.[2];
        var visitCount = cookieVisitorIdValue?.[3];
        var currentVisitTs = cookieVisitorIdValue?.[4];
        var lastVisitTs = cookieVisitorIdValue?.[5];

        return {
          newVisitor,
          uuid,
          createTs,
          visitCount,
          currentVisitTs,
          lastVisitTs
        };
      }
      function getRemainingVisitorCookieTimeout() {
        var now = new Date();
        var nowTs = now?.getTime();
        var cookieCreatedTs = getValuesFromVisitorIdCookie()?.createTs;

        var createTs = parseInt(cookieCreatedTs, 10);
        var originalTimeout =
          createTs * 1000 + configVisitorCookieTimeout - nowTs;
        return originalTimeout;
      }
      function setVisitorIdCookie(visitorIdCookieValues) {
        if (!configTrackerTenantId) {
          // when called before Site ID was set
          return;
        }

        var now = new Date();
        var nowTs = Math?.round(now?.getTime() / 1000);

        if (!isDefined(visitorIdCookieValues)) {
          visitorIdCookieValues = getValuesFromVisitorIdCookie();
        }
        var cookieValue = `${customVisitorId || visitorIdCookieValues?.uuid}.${
          visitorIdCookieValues?.createTs
        }.${visitorIdCookieValues?.visitCount}.${nowTs}.${
          visitorIdCookieValues?.lastVisitTs
        }`;

        setCookie(
          getCookieName('id'),
          cookieValue,
          getRemainingVisitorCookieTimeout(),
          configCookiePath,
          configCookieDomain
        );
      }
      function loadReferrerAttributionCookie() {
        var cookie = getCookie(getCookieName('ref'));
        if (cookie?.length) {
          try {
            cookie = JSON_WSM?.parse(cookie);
            if (isObject(cookie)) {
              return cookie;
            }
          } catch (ignore) {
            // Pre 1.3, this cookie was not JSON encoded
          }
        }

        return ['', '', 0, ''];
      }

      function deleteCookie(cookieName, path, domain) {
        setCookie(cookieName, '', -86400, path, domain);
      }

      function isPossibleToSetCookieOnDomain(domainToTest) {
        var valueToSet = 'testvalue';
        setCookie('test', valueToSet, 10000, null, domainToTest);

        if (getCookie('test') === valueToSet) {
          deleteCookie('test', null, domainToTest);

          return true;
        }

        return false;
      }

      function deleteCookies() {
        var savedConfigCookiesDisabled = configCookiesDisabled;

        // Temporarily allow cookies just to delete the existing ones
        configCookiesDisabled = false;

        var cookiesToDelete = ['id', 'ses', 'ref'];
        var index;
        var cookieName;

        for (index = 0; index < cookiesToDelete?.length; index++) {
          cookieName = getCookieName(cookiesToDelete?.[index]);
          if (getCookie(cookieName) !== 0) {
            deleteCookie(cookieName, configCookiePath, configCookieDomain);
          }
        }

        configCookiesDisabled = savedConfigCookiesDisabled;
      }

      function setTenantId(tenantId) {
        configTrackerTenantId = tenantId;
        setVisitorIdCookie();
      }
      function setSessionCookie() {
        setCookie(
          getCookieName('ses'),
          '*',
          configSessionCookieTimeout,
          configCookiePath,
          configCookieDomain
        );
      }

      function generateUniqueId() {
        var id = '';
        var chars =
          'abcdefghijklmnopqrstuvwxyz0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        var charLen = chars?.length;
        var i;

        for (i = 0; i < 6; i++) {
          id += chars?.charAt(Math?.floor(Math?.random() * charLen));
        }

        return id;
      }
      // other parameters of wsmGetRequest customData, pluginMethod

      function wsmGetRequest(request) {
        var now = new Date();
        var nowTs = Math?.round(now?.getTime() / 1000);
        var referralTs;
        var referralUrl;
        var referralUrlMaxLength = 1024;
        var currentReferrerHostName;
        var originalReferrerHostName;
        var cookieSessionName = getCookieName('ses');
        var cookieReferrerName = getCookieName('ref');
        var cookieSessionValue = getCookie(cookieSessionName);
        var attributionCookie = loadReferrerAttributionCookie();
        var campaignNameDetected;
        var campaignKeywordDetected;

        if (configCookiesDisabled) {
          deleteCookies();
        }

        if (configDoNotTrack) {
          return '';
        }

        var cookieVisitorIdValues = getValuesFromVisitorIdCookie();

        // send charset if document charset is not utf-8. sometimes encoding
        // of urls will be the same as this and not utf-8, which will cause problems
        // do not send charset if it is utf8 since it's assumed by default in Wsm
        var charSet = document?.characterSet || document?.charset;

        if (!charSet || charSet?.toLowerCase() === 'utf-8') {
          charSet = null;
        }

        campaignNameDetected = attributionCookie?.[0];
        campaignKeywordDetected = attributionCookie?.[1];
        referralTs = attributionCookie?.[2];
        referralUrl = attributionCookie?.[3];

        if (!cookieSessionValue) {
          // cookie 'ses' was not found: we consider this the start of a 'session'

          // here we make sure that if 'ses' cookie is deleted few times within the visit
          // and so this code path is triggered many times for one visit,
          // we only increase visitCount once per Visit window (default 30min)
          var visitDuration = configSessionCookieTimeout / 1000;
          if (
            !cookieVisitorIdValues?.lastVisitTs ||
            nowTs - cookieVisitorIdValues?.lastVisitTs > visitDuration
          ) {
            cookieVisitorIdValues.visitCount++;
            cookieVisitorIdValues.lastVisitTs =
              cookieVisitorIdValues?.currentVisitTs;
          }
          // Store the referrer URL and time in the cookie;
          // referral URL depends on the first or last referrer attribution
          currentReferrerHostName = getHostName(configReferrerUrl);
          originalReferrerHostName = referralUrl?.length
            ? getHostName(referralUrl)
            : '';

          if (
            currentReferrerHostName?.length && // there is a referrer
            !isSiteHostName(currentReferrerHostName) && // domain is not the current domain
            (!configConversionAttributionFirstReferrer || // attribute to last known referrer
              !originalReferrerHostName?.length || // previously empty
              isSiteHostName(originalReferrerHostName))
          ) {
            // previously set but in current domain
            referralUrl = configReferrerUrl;
          }

          // Set the referral cookie if we have either a Referrer URL, or detected a Campaign (or both)
          if (referralUrl?.length || campaignNameDetected?.length) {
            referralTs = nowTs;
            attributionCookie = [
              campaignNameDetected,
              campaignKeywordDetected,
              referralTs,
              purify(referralUrl?.slice(0, referralUrlMaxLength))
            ];

            setCookie(
              cookieReferrerName,
              JSON_WSM?.stringify(attributionCookie),
              configReferralCookieTimeout,
              configCookiePath,
              configCookieDomain
            );
          }
        }
        if (String(referralUrl).length) {
          var tempA = document?.createElement('a');
          tempA?.setAttribute('href', referralUrl);
          // if (tempA.protocol === 'https:') {
          //   refUrlType = 'ssl';
          // }
          referralUrl = purify(referralUrl?.slice(0, referralUrlMaxLength));
          var scheme = `${tempA?.protocol}//`;
          referralUrl = referralUrl?.replace(scheme, '');
        }
        if (String(wpUrlReferrer)?.length) {
          var tempB = document?.createElement('a');
          tempB?.setAttribute('href', wpUrlReferrer);
        }

        let device;
        if (isMobileOnly) {
          device = 'Mobile';
        } else if (isTablet) {
          device = 'Tablet';
        } else {
          device = 'Desktop';
        }
        let finalRequest = {
          uniqueId: cookieVisitorIdValues?.uuid,
          referringWebsite: winEncodeWrapper(referralUrl) || '',
          device,
          pageLevel: winEncodeWrapper(titleFixup(configTitle)),
          zipCode,
          ping: false,
          ...configAppendToTrackingUrl,
          ...request
        };

        setVisitorIdCookie(cookieVisitorIdValues);
        setSessionCookie();

        return finalRequest;
      }
      heartBeatPingIfActivityAlias = function heartBeatPingIfActivity() {
        var now = new Date();
        // console.log("lastTrackerRequestTime + configHeartBeatDelay <= now.getTime()="+lastTrackerRequestTime+"+"+configHeartBeatDelay+"<="+now.getTime());
        if (lastTrackerRequestTime + configHeartBeatDelay <= now?.getTime()) {
          var requestPing = wsmGetRequest({ ping: true }, null, 'ping');
          wsmSendRequest(requestPing, configTrackerPause);
          return true;
        }
        return false;
      };
      function logPageView(customTitle, customData, callback) {
        configIdPageView = generateUniqueId();
        var request = wsmGetRequest(
          {
            pageLevel: winEncodeWrapper(titleFixup(customTitle || configTitle))
          },
          customData,
          'log'
        );
        wsmSendRequest(request, configTrackerPause, callback);
      }

      function startsUrlWithTrackerUrl(url) {
        return (
          configTrackerUrl &&
          url &&
          String(url)?.indexOf(configTrackerUrl) === 0
        );
      }

      function getSourceElement(sourceElement) {
        var parentElement;

        parentElement = sourceElement.parentNode;
        while (
          parentElement !== null &&
          /* buggy IE5.5 */
          isDefined(parentElement)
        ) {
          if (wsmQuery?.isLinkElement(sourceElement)) {
            break;
          }
          sourceElement = parentElement;
          parentElement = sourceElement.parentNode;
        }

        return sourceElement;
      }

      function getLinkIfShouldBeProcessed(sourceElement) {
        sourceElement = getSourceElement(sourceElement);

        if (!wsmQuery?.hasNodeAttribute(sourceElement, 'href')) {
          return;
        }

        if (!isDefined(sourceElement?.href)) {
          return;
        }

        var href = wsmQuery?.getAttributeValueFromNode(sourceElement, 'href');

        if (startsUrlWithTrackerUrl(href)) {
          return;
        }

        // browsers, such as Safari, don't downcase hostname and href
        var originalSourceHostName =
          sourceElement?.hostname || getHostName(sourceElement?.href);
        var sourceHostName = originalSourceHostName?.toLowerCase();
        var sourceHref = sourceElement?.href?.replace(
          originalSourceHostName,
          sourceHostName
        );

        // browsers, such as Safari, don't downcase hostname and href
        var scriptProtocol = new RegExp(
          '^(javascript|vbscript|jscript|mocha|livescript|ecmascript|mailto|tel):',
          'i'
        );

        if (!scriptProtocol?.test(sourceHref)) {
          return {
            type: 'link',
            href: sourceHref
          };
        }
      }

      function logLink(url, linkType, customData, callback) {
        var linkParams = {
          [linkType]: winEncodeWrapper(purify(url))
        };

        var request = wsmGetRequest(linkParams, customData, 'link');

        wsmSendRequest(request, configTrackerPause, callback);
      }

      function prefixPropertyName(prefix, propertyName) {
        if (prefix !== '') {
          return (
            prefix +
            propertyName?.charAt(0)?.toUpperCase() +
            propertyName?.slice(1)
          );
        }

        return propertyName;
      }
      function trackCallback(callback) {
        var isPreRendered;
        var i;
        // Chrome 13, IE10, FF10
        var prefixes = ['', 'webkit', 'ms', 'moz'];
        var prefix;

        if (!configCountPreRendered) {
          for (i = 0; i < prefixes?.length; i++) {
            prefix = prefixes?.[i];
            // does this browser support the page visibility API?
            if (
              Object?.prototype?.hasOwnProperty?.call(
                document,
                prefixPropertyName(prefix, 'hidden')
              )
            ) {
              // if pre-rendered, then defer callback until page visibility changes
              if (
                document[prefixPropertyName(prefix, 'visibilityState')] ===
                'prerender'
              ) {
                isPreRendered = true;
              }
              break;
            }
          }
        }

        if (isPreRendered) {
          // note: the event name doesn't follow the same naming convention as vendor properties
          addEventListener(
            document,
            `${prefix}visibilitychange`,
            function ready() {
              document?.removeEventListener(
                `${prefix}visibilitychange`,
                ready,
                false
              );
              callback();
            }
          );

          return;
        }

        callback();
      }

      /*
       * Process clicks
       */
      function processClick(sourceElement) {
        var link = getLinkIfShouldBeProcessed(sourceElement);

        if (link && link?.type) {
          link.href = safeDecodeWrapper(link?.href);
          logLink(link?.href, link?.type, undefined, null, sourceElement);
        }
      }

      function isIE8orOlder() {
        return document?.all && !document?.addEventListener;
      }

      function getKeyCodeFromEvent(event) {
        // event.which is deprecated https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/which
        var which = event?.which;

        /**
                   1 : Left mouse button
                   2 : Wheel button or middle button
                   3 : Right mouse button
                   */

        var typeOfEventButton = typeof event?.button;

        if (!which && typeOfEventButton !== 'undefined') {
          /**
                       -1: No button pressed
                       0 : Main button pressed, usually the left button
                       1 : Auxiliary button pressed, usually the wheel button or themiddle button (if present)
                       2 : Secondary button pressed, usually the right button
                       3 : Fourth button, typically the Browser Back button
                       4 : Fifth button, typically the Browser Forward button

                       IE8 and earlier has different values:
                       1 : Left mouse button
                       2 : Right mouse button
                       4 : Wheel button or middle button

                       For a left-hand configured mouse, the return values are reversed. We do not take care of that.
                       */

          if (isIE8orOlder()) {
            if (event?.button & 1) {
              which = 1;
            } else if (event?.button & 2) {
              which = 3;
            } else if (event?.button & 4) {
              which = 2;
            }
          } else if (event?.button === 0 || event?.button === '0') {
            which = 1;
          } else if (event?.button & 1) {
            which = 2;
          } else if (event?.button & 2) {
            which = 3;
          }
        }

        return which;
      }

      function getNameOfClickedButton(event) {
        switch (getKeyCodeFromEvent(event)) {
          default:
          case 1:
            return 'left';
          case 2:
            return 'middle';
          case 3:
            return 'right';
        }
      }

      function getTargetElementFromEvent(event) {
        return event?.target || event?.srcElement;
      }

      /*
       * Handle click event
       */
      function clickHandler(enable) {
        return function (event) {
          event = event || window?.event;

          var button = getNameOfClickedButton(event);
          var target = getTargetElementFromEvent(event);
          if (event?.type === 'click') {
            var ignoreClick = false;
            if (enable && button === 'middle') {
              ignoreClick = true;
            }

            if (target && !ignoreClick) {
              processClick(target);
            }
          } else if (event?.type === 'mousedown') {
            if (button === 'middle' && target) {
              lastButton = button;
              lastTarget = target;
            } else {
              lastButton = null;
              lastTarget = null;
            }
          } else if (event.type === 'mouseup') {
            if (button === lastButton && target === lastTarget) {
              processClick(target);
            }
            lastButton = null;
            lastTarget = null;
          } else if (event?.type === 'contextmenu') {
            processClick(target);
          }
        };
      }

      /*
       * Add click listener to a DOM element
       */
      function addClickListener(element, enable) {
        var enableType = typeof enable;
        if (enableType === 'undefined') {
          enable = true;
        }
        addEventListener(element, 'click', clickHandler(enable), false);
        if (enable) {
          addEventListener(element, 'mouseup', clickHandler(enable), false);
          addEventListener(element, 'mousedown', clickHandler(enable), false);
          addEventListener(element, 'contextmenu', clickHandler(enable), false);
        }
      }

      /*
       * Add click handlers to anchor and AREA elements, except those to be ignored
       */
      function addClickListeners(enable) {
        if (!linkTrackingInstalled) {
          linkTrackingInstalled = true;
          // var i;
          var linkElements = document?.links;
          if (linkElements) {
            // eslint-disable-next-line no-restricted-syntax
            for (let item of linkElements) {
              addClickListener(item, enable);
            }
          }
        }
      }

      function detectBrowserFeatures() {
        var i;
        var mimeType;
        var pluginMap = {
          // document types
          pdf: 'application/pdf',

          // media players
          qt: 'video/quicktime',
          rp: 'audio/x-pn-realaudio-plugin',
          wma: 'application/x-mplayer2',

          // interactive multimedia
          dir: 'application/x-director',
          fla: 'application/x-shockwave-flash',

          // RIA
          java: 'application/x-java-vm',
          gears: 'application/x-googlegears',
          ag: 'application/x-silverlight'
        };

        if (!new RegExp('MSIE')?.test(navigator?.userAgent)) {
          if (navigator?.mimeTypes && navigator?.mimeTypes.length) {
            // eslint-disable-next-line no-restricted-syntax
            for (i in pluginMap) {
              if (Object?.prototype?.hasOwnProperty?.call(pluginMap, i)) {
                mimeType = navigator?.mimeTypes[pluginMap[i]];
                browserFeatures[i] =
                  mimeType && mimeType?.enabledPlugin ? '1' : '0';
              }
            }
          }
          if (isDefined(navigator?.javaEnabled) && navigator?.javaEnabled()) {
            browserFeatures.java = '1';
          }
          if (isFunction(window?.GearsFactory)) {
            browserFeatures.gears = '1';
          }
          browserFeatures.cookie = hasCookies();
        }

        var width = parseInt(window?.screen?.width, 10);
        var height = parseInt(window?.screen?.height, 10);
        browserFeatures.res = `${parseInt(width, 10)}x${parseInt(height, 10)}`;
      }
      /*
       * Register a test hook. Using eval() permits access to otherwise
       * privileged members.
       */
      function registerHook(hookName, userHook) {
        var hookObj = null;

        if (
          isString(hookName) &&
          !isDefined(registeredHooks?.[hookName]) &&
          userHook
        ) {
          if (isObject(userHook)) {
            hookObj = userHook;
          } else if (isString(userHook)) {
            try {
              // eslint-disable-next-line no-eval
              eval(`hookObj =${userHook}`);
              // hookObj = userHook;
            } catch (ignore) {
              // this comment fix empty blocks
            }
          }

          registeredHooks[hookName] = hookObj;
        }

        return hookObj;
      }

      detectBrowserFeatures();
      updateDomainHash();
      setVisitorIdCookie();
      executePluginMethod('run', registerHook);
      return {
        hook: registeredHooks,
        getHook(hookName) {
          return registeredHooks?.[hookName];
        },
        getQuery() {
          return wsmQuery;
        },
        getContent() {
          return content;
        },
        trackCallbackOnLoad,
        trackCallbackOnReady,
        getDomains() {
          return configHostsAlias;
        },
        getConfigCookiePath() {
          return configCookiePath;
        },
        getConfigIdPageView() {
          return configIdPageView;
        },
        disableLinkTracking() {
          linkTrackingInstalled = false;
          // linkTrackingEnabled = false;
        },
        getConfigVisitorCookieTimeout() {
          return configVisitorCookieTimeout;
        },
        clearTimer() {
          var timeOut = arrTimeOuts?.pop();
          if (timeOut) {
            clearTimeout(timeOut);
            this?.clearTimer();
          }
        },
        removeAllTrackers() {
          arrTrackers = [];
          if (arrTimeOuts?.length) {
            this?.clearTimer();
          }
        },
        removeAllTrackersButFirst() {
          var firstTracker = arrTrackers?.[0];
          arrTrackers = [firstTracker];
        },
        getRemainingVisitorCookieTimeout,
        getVisitorId() {
          return getValuesFromVisitorIdCookie()?.uuid;
        },
        getVisitorInfo() {
          return loadVisitorIdCookie();
        },
        getAttributionInfo() {
          return loadReferrerAttributionCookie();
        },
        getAttributionReferrerTimestamp() {
          return loadReferrerAttributionCookie()?.[2];
        },
        getAttributionReferrerUrl() {
          return loadReferrerAttributionCookie()?.[3];
        },
        setTrackerUrl(trackerUrl) {
          configTrackerUrl = trackerUrl;
        },
        getTrackerUrl() {
          return configTrackerUrl;
        },
        newTracker(wsmUrl, tenantId) {
          if (!tenantId) {
            throw new Error('A tenantId must be given to add a new tracker');
          }

          if (!isDefined(wsmUrl) || wsmUrl === null) {
            wsmUrl = this?.getTrackerUrl();
          }

          var tracker = new WsmTracker(wsmUrl, tenantId);
          arrTrackers?.push(tracker);
          return tracker;
        },
        getTenantId() {
          return configTrackerTenantId;
        },
        setTenantId(tenantId) {
          setTenantId(tenantId);
        },
        setZipCode(zipCodeCopy) {
          zipCode = zipCodeCopy;
        },
        setSlug(slugCopy) {
          slug = slugCopy;
        },
        setProjectData(projectWidgetObject) {
          selectedSubAreaId = projectWidgetObject?.subAreaId;
          selectedLineOfBusinessKey = projectWidgetObject?.lineOfBusinessKey;
        },
        setAnalyticsData(analyticsObj) {
          analyticsData = { ...analyticsObj };
        },
        setIsSubmit(value = false) {
          isSubmit = value;
        },
        setIsBackPing(value = false) {
          isBackPing = value;
        },
        setRoqPathId(id) {
          roqPathId = id;
        },
        getVisitorUniqueId() {
          return getValuesFromVisitorIdCookie()?.uuid;
        },
        setSubDomain(subDomainCopy) {
          subDomain = subDomainCopy;
        },
        // we are setting the custom visitor Id for managing lead ID flow
        setVisitorFromBE(visitorId) {
          customVisitorId = visitorId;
        },
        setUserId(userId) {
          if (!isDefined(userId) || !userId?.length) {
            return;
          }
          configUserId = userId;
          visitorUUID = hash(configUserId)?.substr(0, 16);
        },
        setWpUserId(userId) {
          if (!isDefined(userId) || !userId?.length) {
            return;
          }
          wpUserId = userId;
        },
        setUrlReferrer(refURL) {
          if (!isDefined(refURL) || !refURL?.length) {
            return;
          }
          wpUrlReferrer = refURL;
        },
        setPageId(pageId) {
          if (!isDefined(pageId) || !pageId?.length) {
            return;
          }
          configPageId = pageId;
        },
        getUserId() {
          return configUserId;
        },
        getPageId() {
          return configPageId;
        },
        appendToTrackingUrl(queryString) {
          configAppendToTrackingUrl = { ...queryString };
        },
        getRequest(request) {
          return wsmGetRequest(request);
        },
        addPlugin(pluginName, pluginObj) {
          plugins[pluginName] = pluginObj;
        },
        setLinkTrackingTimer(delay) {
          configTrackerPause = delay;
        },
        setDomains(hostsAlias) {
          configHostsAlias = isString(hostsAlias) ? [hostsAlias] : hostsAlias;

          var hasDomainAliasAlready = false;
          var i = 0;
          var alias;
          for (i; i < configHostsAlias?.length; i++) {
            alias = String(configHostsAlias?.[i]);

            if (isSameHost(domainAlias, domainFixup(alias))) {
              hasDomainAliasAlready = true;
              break;
            }

            var pathName = getPathName(alias);
            if (pathName && pathName !== '/' && pathName !== '/*') {
              hasDomainAliasAlready = true;
              break;
            }
          }
          if (!hasDomainAliasAlready) {
            configHostsAlias?.push(domainAlias);
          }
        },
        setRequestMethod(method) {
          configRequestMethod = method || defaultRequestMethod;
        },
        setRequestContentType(requestContentType) {
          configRequestContentType =
            requestContentType || defaultRequestContentType;
        },
        setReferrerUrl(url) {
          configReferrerUrl = url;
        },
        setPageTitle(title) {
          configTitle = title;
        },
        discardHashTag(enableFilter) {
          configDiscardHashTag = enableFilter;
        },
        setCookieNamePrefix(cookieNamePrefix) {
          configCookieNamePrefix = cookieNamePrefix;
          // Re-init the Custom Variables cookie
        },
        setCookieDomain(domain) {
          var domainFixed = domainFixup(domain);

          if (isPossibleToSetCookieOnDomain(domainFixed)) {
            configCookieDomain = domainFixed;
            updateDomainHash();
          }
        },
        setCookiePath(path) {
          configCookiePath = path;
          updateDomainHash();
        },
        setVisitorCookieTimeout(timeout) {
          configVisitorCookieTimeout = timeout * 1000;
        },
        setSessionCookieTimeout(timeout) {
          configSessionCookieTimeout = timeout * 1000;
        },
        setReferralCookieTimeout(timeout) {
          configReferralCookieTimeout = timeout * 1000;
        },
        setConversionAttributionFirstReferrer(enable) {
          configConversionAttributionFirstReferrer = enable;
        },
        disableCookies() {
          configCookiesDisabled = true;
          browserFeatures.cookie = '0';

          if (configTrackerTenantId) {
            deleteCookies();
          }
        },
        deleteCookies() {
          deleteCookies();
        },
        setDoNotTrack(enable) {
          var dnt = navigator?.doNotTrack || navigator?.msDoNotTrack;
          configDoNotTrack = enable && (dnt === 'yes' || dnt === '1');

          // do not track also disables cookies and deletes existing cookies
          if (configDoNotTrack) {
            this.disableCookies();
          }
        },
        addListener(element, enable) {
          document?.addEventListener('DOMContentLoaded', function () {
            addClickListener(element, enable);
          });
        },
        enableLinkTracking(enable) {
          // linkTrackingEnabled = true;

          trackCallback(function () {
            trackCallbackOnReady(function () {
              document?.addEventListener('DOMContentLoaded', function () {
                addClickListeners(enable);
              });
            });
          });
        },

        enableHeartBeatTimer(heartBeatDelayInSeconds) {
          heartBeatDelayInSeconds = Math?.max(heartBeatDelayInSeconds, 1);
          configHeartBeatDelay = (heartBeatDelayInSeconds || 15) * 1000;
          // if a tracking request has already been sent, start the heart beat timeout
          if (lastTrackerRequestTime !== null) {
            setUpHeartBeat();
          }
        },
        disableHeartBeatTimer() {
          heartBeatDown();
          configHeartBeatDelay = null;

          window?.removeEventListener('focus', heartBeatOnFocus);
          window?.removeEventListener('blur', heartBeatOnBlur);
        },
        killFrame() {
          if (window?.location !== window?.top?.location) {
            window.top.location = window?.location;
          }
        },
        redirectFile(url) {
          if (window?.location?.protocol === 'file:') {
            window.location = url;
          }
        },
        setCountPreRendered(enable) {
          configCountPreRendered = enable;
        },
        trackLink(sourceUrl, linkType, customData, callback) {
          trackCallback(function () {
            logLink(sourceUrl, linkType, customData, callback);
          });
        },
        trackPageView(customTitle, customData, callback) {
          trackCallback(function () {
            logPageView(customTitle, customData, callback);
          });
        },
        trackRequest(request, customData, callback) {
          trackCallback(function () {
            var fullRequest = wsmGetRequest(request, customData);
            wsmSendRequest(fullRequest, configTrackerPause, callback);
          });
        }
      };
    }
    function WsmTrackerProxy() {
      return {
        push: apply
      };
    }
    function applyMethodsInOrder(wsm, methodsToApply) {
      var appliedMethods = {};
      var index;
      var iterator;

      for (index = 0; index < methodsToApply?.length; index++) {
        var methodNameToApply = methodsToApply?.[index];
        appliedMethods[methodNameToApply] = 1;

        for (iterator = 0; iterator < wsm?.length; iterator++) {
          if (wsm?.[iterator]?.[0]) {
            var methodName = wsm?.[iterator]?.[0];

            if (methodNameToApply === methodName) {
              apply(wsm?.[iterator]);
              delete wsm?.[iterator];

              if (appliedMethods?.[methodName] > 1) {
                logConsoleError(
                  `The method ${methodName} is registered more than once in "_wsm" variable. Only the last call has an effect. Please have a look at the multiple Wsm trackers documentation: http://developer.wsm.org/guides/tracking-javascript-guide#multiple-wms-trackers`
                );
              }
              appliedMethods[methodName]++;
            }
          }
        }
      }
      return wsm;
    }
    // eslint-disable-next-line no-extend-native
    Date.prototype.getTimeAlias = Date?.prototype?.getTime;
    const applyFirst = [
      'newTracker',
      'disableCookies',
      'setTrackerUrl',
      'setCookiePath',
      'setCookieDomain',
      'setDomains',
      'setWpUserId',
      'setPageId',
      'setTenantId',
      'enableLinkTracking',
      'setUrlReferrer'
    ];
    function wsmCreateFirstTracker(wsmUrl, tenantId) {
      const tracker = new WsmTracker(wsmUrl, tenantId);
      arrTrackers?.push(tracker);

      _wsm = applyMethodsInOrder(_wsm, applyFirst);

      // apply the queue of actions
      for (iterator = 0; iterator < _wsm?.length; iterator++) {
        if (_wsm?.[iterator]) {
          apply(_wsm?.[iterator]);
        }
      }
      // replace initialization array with proxy object
      _wsm = new WsmTrackerProxy();
      return tracker;
    }
    // replace initialization array with proxy object
    // _wsm = new wsmTrackerProxy();
    Wsm = {
      initialized: false,
      JSON: JSON_WSM,
      getTracker(wsmUrl, tenantId) {
        if (!isDefined(tenantId)) {
          tenantId = this?.getAsyncTracker()?.getTenantId();
        }
        if (!isDefined(wsmUrl)) {
          wsmUrl = this?.getAsyncTracker()?.getTrackerUrl();
        }

        return new WsmTracker(wsmUrl, tenantId);
      },
      getAsyncTracker(wsmUrl, tenantId) {
        var firstTracker;
        if (arrTrackers && arrTrackers?.length && arrTrackers?.[0]) {
          firstTracker = arrTrackers?.[0];
        } else {
          return wsmCreateFirstTracker(wsmUrl, tenantId);
        }

        if (!tenantId && !wsmUrl) {
          return firstTracker;
        }
        if ((!isDefined(tenantId) || tenantId === null) && firstTracker) {
          tenantId = firstTracker?.getTenantId();
        }

        if ((!isDefined(wsmUrl) || wsmUrl === null) && firstTracker) {
          wsmUrl = firstTracker?.getTrackerUrl();
        }

        var tracker;
        var i = 0;
        for (i; i < arrTrackers?.length; i++) {
          tracker = arrTrackers?.[i];
          if (
            tracker &&
            String(tracker?.getTenantId()) === String(tenantId) &&
            tracker?.getTrackerUrl() === wsmUrl
          ) {
            return tracker;
          }
        }
      },
      getAllTrackers() {
        return arrTrackers;
      },
      newTracker(wsmUrl, tenantId) {
        if (!arrTrackers?.length) {
          wsmCreateFirstTracker(wsmUrl, tenantId);
        } else {
          arrTrackers?.[0]?.newTracker(wsmUrl, tenantId);
        }
      }
    };
    // Expose Wsm as an AMD module
    if (typeof define === 'function' && define?.amd) {
      define('wsm', [], function () {
        return Wsm;
      });
    }
    return Wsm;
  })();
}
window.Wsm.initialized = true;
