import { gql } from '@apollo/client';

export const VALIDATE_LEAD_ID = gql`
  query validateLeadId($where: ValidateLeadWhere!) {
    validateLeadId(where: $where) {
      zipCode
      customerName
      email
      mobileNumber
      industry {
        id
        label
        key
      }
      lineOfBusiness {
        id
        label
        key
      }
      subArea {
        id
        label
        key
      }
      appointmentTime
      comments
      contactMethods
      location {
        addressLine1
        addressLine2
        addressLine3
        state
        county
        city
      }
      systemFields
    }
  }
`;

export const GET_PAGE_SEQUENCES = gql`
  query pageSequences($where: PageConfigWhereInput!) {
    pageSequences(where: $where) {
      count
      selectedFromRest
      data {
        id
        allowDrag
        questionnaireId
        order
        pageKey
        title
        order
        primaryColor
        tenantId
        phoneNo
        email
        liveChat
        helpPage
        brandConfig
        industryId
        tenantLogo {
          url
          key
          name
          extension
          contentType
        }
        tenantName
        logo {
          url
          key
          name
          extension
          contentType
        }
        appointmentWindow
        daysOff
        holidayList {
          title
          date
          message
        }
        cutOffTime {
          monday {
            checked
            time
          }
          tuesday {
            checked
            time
          }
          wednesday {
            checked
            time
          }
          thursday {
            checked
            time
          }
          friday {
            checked
            time
          }
          saturday {
            checked
            time
          }
          sunday {
            checked
            time
          }
        }
        termsOfUse
        privacyPolicy
        pageConfiguration {
          termsOfUseCheck
          privacyPolicyCheck
          redirectUrlEnabled
          redirectUrl
          id
          questionnaireId
          title
          showTitle
          taglineAbove
          showTaglineAbove
          pageKey
          taglineBelow
          showTaglineBelow
          skipPageConditions
          showHeaderImage
          headerImage {
            url
            key
            name
            extension
            contentType
          }
          widgetConfiguration
          widgetId
          widgetType
          showLogo
          showContactInfo
          sameDayInstallationTimer
          showTagLine
          showCtaButton
          ctaButtonLabel
          showLocation
          showBack
          pageTitleAsBackButton
          tenantId
          showMyCart
          showFootNote
          footNote
          showContactMe
          contactMe
          allowDrag
          pageKey
        }
        fieldConfig
        defaultPickListOption
        pageSkipFromParams
      }
      analyticsServiceProviders {
        id
        name
        code
      }
    }
  }
`;

export const GET_PROJECT_LOB_LISTING = gql`
  query projectListing(
    $filter: LineOfBusinessFilter!
    $where: CPQBuilderQuestionnaireWhereInput
  ) {
    projectListing(filter: $filter, where: $where) {
      count
      data {
        id
        label
        key
        lobCode
        description
        subAreas {
          id
          label
          key
          saCode
          description
          estimation
          isActive
          waiverText
        }
        createdAt
      }
    }
  }
`;

export const GET_LEAD_STORE_DATA = gql`
  query getLeadStoreData($where: LeadStoreWhereInput!) {
    getLeadStoreData(where: $where) {
      data {
        id
        data
        visitorId
      }
      visitorId
    }
  }
`;

export const FETCH_PRODUCTS = gql`
  query fetchProducts($where: FetchQuoteWhereInput!) {
    fetchProducts(where: $where) {
      compareConfigData {
        id
        enable
        label
        title
        staticKeys
        dynamicFields {
          id
          label
          key
          config
          dynamicListItem {
            id
            label
            value
          }
        }
      }
      good {
        id
        title
        description
        sellingPrice
        actualPrice
        costPerDay
        quoteLabel
        attachments {
          id
          url
          label
        }
        pricingModifierObj
        productItemInfo {
          id
          total
          isPrimary
          quantity
          name
          price
          quoteDescription
          manufacturer
          stock
          unitOfMeasure
          productCategory
          productWarranty
          labourWarranty
          manageInventory
          asset
          allowPriceChange
          dynamicFields
          volume
          symbol
          calculation
          convertedVolume
          sku
        }
        attachments {
          id
          key
          url
          label
        }
      }
      better {
        id
        title
        description
        sellingPrice
        actualPrice
        costPerDay
        quoteLabel
        attachments {
          id
          url
          label
        }
        pricingModifierObj
        productItemInfo {
          id
          total
          isPrimary
          quantity
          name
          price
          quoteDescription
          manufacturer
          stock
          unitOfMeasure
          productCategory
          productWarranty
          labourWarranty
          manageInventory
          asset
          allowPriceChange
          dynamicFields
          volume
          symbol
          calculation
          convertedVolume
          sku
        }
        attachments {
          id
          key
          url
          label
        }
      }
      best {
        id
        title
        description
        sellingPrice
        actualPrice
        costPerDay
        quoteLabel
        attachments {
          id
          url
          label
        }
        pricingModifierObj
        productItemInfo {
          id
          total
          isPrimary
          quantity
          name
          price
          quoteDescription
          manufacturer
          stock
          unitOfMeasure
          productCategory
          productWarranty
          labourWarranty
          manageInventory
          asset
          allowPriceChange
          dynamicFields
          volume
          symbol
          calculation
          convertedVolume
          sku
        }
        attachments {
          id
          key
          url
          label
        }
      }
    }
  }
`;

export const VALIDATE_ZIP_CODE = gql`
  query validateZipCode($where: ZipCodeWhereInput!) {
    validateZipCode(where: $where)
  }
`;

export const GET_ADD_ON_PRODUCTS = gql`
  query AddOnProducts($where: AddOnProductsWhereInput!) {
    addOnProducts(where: $where) {
      data {
        id
        name
        minimumQuantity
        maximumQuantity
        defaultPrice
        costPerDay
        quoteDescription
      }
    }
  }
`;
