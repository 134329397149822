import Hotjar from '@hotjar/browser';
import { groupBy } from 'lodash';
import { useEffect, useMemo } from 'react';
import TagManager from 'react-gtm-module';

const HOTJAR_VERSION = '6';

const DynamicScriptsInjector = ({ analyticsData }) => {
  const analytics = useMemo(() => groupBy(analyticsData, 'name') ?? {}, [
    analyticsData
  ]);

  useEffect(() => {
    const {
      GOOGLE: googleTagManagerIds = [],
      CUSTOM: customEmbedCodes = [],
      HOTJAR: hotJarIds = []
    } = analytics;

    // commented for future use
    // const isScriptAlreadyInjected = (id) => {
    //   // eslint-disable-next-line no-undef
    //   const scripts = document.getElementsByTagName('script');
    //   const exists =
    //     Array.from(scripts).find((script) => script?.id === id) !== undefined;
    //   return exists;
    // };
    const injectGoogleTagManager = () => {
      googleTagManagerIds.forEach((id) => {
        if (id?.code?.data) {
          TagManager?.initialize({
            gtmId: id?.code?.data
          });
        }
      });
    };
    const injectHotJar = () => {
      hotJarIds.forEach((id) => {
        if (id?.code?.data) {
          Hotjar?.init(id?.code?.data, HOTJAR_VERSION);
        }
      });
    };
    injectGoogleTagManager();
    injectHotJar();
    // Inject custom embed codes
    customEmbedCodes.forEach((code) => {
      if (code?.code?.data) {
        if (code?.code?.location === 'BODY') {
          // eslint-disable-next-line no-undef
          document?.body?.appendChild(
            // eslint-disable-next-line no-undef
            document.createRange().createContextualFragment(code?.code?.data)
          );
        } else {
          // eslint-disable-next-line no-undef
          document?.head?.appendChild(
            // eslint-disable-next-line no-undef
            document.createRange().createContextualFragment(code?.code?.data)
          );
        }
      }
    });
  }, [analytics]);

  return null;
};

export default DynamicScriptsInjector;
