import { Form } from 'antd';
import { filter, find, forEach, isEmpty, map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import { DYNAMIC_FIELDS_TYPE, PRIMARY_COLOR } from '../../common/constants';
import { sendAnalyticsData } from '../../common/utils';
import CommonPickListWidget from '../CommonPickListWidget';
import SelectComponent from '../SelectComponent';

const { Option } = SelectComponent;

const RenderSystemPickList = ({
  widgetConfig = null,
  editData = null,
  savedData,
  setSavedData,
  defaultPickListOption = null,
  form = null,
  pageId = null,
  primaryColor = PRIMARY_COLOR,
  type = DYNAMIC_FIELDS_TYPE?.PICK_LIST
}) => {
  const { dispatch } = useContext(AppContext);
  const displayStyle = widgetConfig?.displayStyle;
  const images = widgetConfig?.images;
  const imagePreview = widgetConfig?.imagePreview;
  const multiSelection = widgetConfig?.multiSelection;
  const renderDescriptions = widgetConfig?.optionDescriptions;
  const otherConfig = widgetConfig?.otherConfig;
  const description = widgetConfig?.description;
  const descriptionText = widgetConfig?.descriptionText;
  const hideContinueButton = widgetConfig?.hideContinueButton;
  const required = widgetConfig?.required;
  const fieldId = widgetConfig?.selectedSystemFieldBlock?.selectedSystemFieldId;
  const systemLabel =
    widgetConfig?.selectedSystemFieldBlock?.selectedSystemFieldLabel;
  const [multipleKeys, setMultipleKeys] = useState([]);
  const [selectedKey, setSelectedKey] = useState(null);

  useEffect(() => {
    if (editData && !isEmpty(editData?.[fieldId])) {
      if (!isEmpty(editData?.[fieldId]?.value)) {
        setSavedData({
          systemFields: {
            ...savedData?.systemFields,
            [fieldId]: {
              type,
              value: [...editData?.[fieldId]?.value],
              pageId,
              ...(widgetConfig?.selectedSystemFieldBlock?.entityName && {
                isFieldQueryable: widgetConfig?.isFieldQueryable,
                entityName: widgetConfig?.selectedSystemFieldBlock?.entityName,
                fieldCategory:
                  widgetConfig?.selectedSystemFieldBlock?.fieldCategory
              })
            }
          }
        });

        form?.setFieldsValue({
          systemFields: {
            [fieldId]: {
              [type]: {
                value: [...editData?.[fieldId]?.value]
              }
            }
          }
        });
        if (!multiSelection) {
          setSelectedKey(editData?.[fieldId]?.value?.[0]);
          // analytics flow
          const option = find(
            otherConfig,
            (item) => editData?.[fieldId]?.value?.[0] === item?.blockId
          );
          sendAnalyticsData({
            [systemLabel]: { key: option?.name, value: option?.label }
          });
        } else {
          setMultipleKeys([...multipleKeys, ...editData?.[fieldId]?.value]);
          // analytics flow
          const data = [];
          forEach(otherConfig, (item) => {
            if (
              [...multipleKeys, ...editData?.[fieldId]?.value]?.includes(
                item?.blockId
              )
            ) {
              data?.push({ key: item?.name, value: item?.label });
            }
          });
          sendAnalyticsData({
            [systemLabel]: data
          });
        }
      } else {
        form?.setFieldsValue({
          systemFields: {
            [fieldId]: {
              [type]: {
                value: []
              }
            }
          }
        });
        if (!multiSelection) {
          setSelectedKey(null);
          // analytics flow
          sendAnalyticsData({
            [systemLabel]: null
          });
        } else {
          setMultipleKeys([]);
          // analytics flow
          sendAnalyticsData({
            [systemLabel]: []
          });
        }
      }
    } else if (defaultPickListOption) {
      setSavedData({
        systemFields: {
          ...savedData?.systemFields,
          [fieldId]: {
            type,
            value: [defaultPickListOption],
            pageId,
            ...(widgetConfig?.selectedSystemFieldBlock?.entityName && {
              isFieldQueryable: widgetConfig?.isFieldQueryable,
              entityName: widgetConfig?.selectedSystemFieldBlock?.entityName,
              fieldCategory:
                widgetConfig?.selectedSystemFieldBlock?.fieldCategory
            })
          }
        }
      });
      form?.setFieldsValue({
        systemFields: {
          [fieldId]: {
            [type]: {
              value: defaultPickListOption
            }
          }
        }
      });
      if (!multiSelection) {
        setSelectedKey(defaultPickListOption);
        // analytics flow
        const option = find(
          otherConfig,
          (item) => defaultPickListOption === item?.blockId
        );
        sendAnalyticsData({
          [systemLabel]: { key: option?.name, value: option?.label }
        });
      } else {
        setMultipleKeys([...multipleKeys, defaultPickListOption]);
        // analytics flow
        const option = find(
          otherConfig,
          (item) => defaultPickListOption === item?.blockId
        );
        sendAnalyticsData({
          [systemLabel]: [{ key: option?.name, value: option?.label }]
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData, defaultPickListOption]);

  // picklist show/hide button logic
  useEffect(() => {
    if (hideContinueButton) {
      // if user want to hide cta button
      dispatch({
        type: 'SET_SHOW_SUBMIT_BTN',
        data: false
      });
    } else if (required && multiSelection) {
      // check if picklist is required and if picklist is multiselect and user doesn't select any option from picklist then hide cta button
      dispatch({
        type: 'SET_SHOW_SUBMIT_BTN',
        data: multipleKeys?.length !== 0
      });
    } else if (required && !multiSelection) {
      // check if picklist is required and if picklist is not multiselect and user doesn't select any option from picklist then hide cta button
      dispatch({
        type: 'SET_SHOW_SUBMIT_BTN',
        data: selectedKey
      });
    } else {
      // by default button will be shown to user
      dispatch({
        type: 'SET_SHOW_SUBMIT_BTN',
        data: true
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multipleKeys, selectedKey]);

  const handleRedirect = (item) => {
    if (!multiSelection) {
      if (selectedKey !== item?.blockId) {
        setSelectedKey(item?.blockId);
        setSavedData({
          systemFields: {
            ...savedData?.systemFields,
            [fieldId]: {
              type,
              value: [item?.blockId],
              pageId,
              ...(widgetConfig?.selectedSystemFieldBlock?.entityName && {
                isFieldQueryable: widgetConfig?.isFieldQueryable,
                entityName: widgetConfig?.selectedSystemFieldBlock?.entityName,
                fieldCategory:
                  widgetConfig?.selectedSystemFieldBlock?.fieldCategory
              })
            }
          }
        });
        // analytics flow
        sendAnalyticsData({
          [systemLabel]: {
            key: item?.name,
            value: item?.label
          }
        });
        if (hideContinueButton) {
          form?.submit();
        }
      } else {
        setSelectedKey();
        setSavedData({
          systemFields: {
            ...savedData?.systemFields,
            [fieldId]: {
              type,
              value: [],
              pageId,
              ...(widgetConfig?.selectedSystemFieldBlock?.entityName && {
                isFieldQueryable: widgetConfig?.isFieldQueryable,
                entityName: widgetConfig?.selectedSystemFieldBlock?.entityName,
                fieldCategory:
                  widgetConfig?.selectedSystemFieldBlock?.fieldCategory
              })
            }
          }
        });
        // analytics flow
        sendAnalyticsData({
          [systemLabel]: null
        });
      }
    } else if (!multipleKeys?.includes(item?.blockId)) {
      setMultipleKeys([...multipleKeys, item?.blockId]);
      setSavedData({
        systemFields: {
          ...savedData?.systemFields,
          [fieldId]: {
            type,
            value: [...multipleKeys, item?.blockId],
            pageId,
            ...(widgetConfig?.selectedSystemFieldBlock?.entityName && {
              isFieldQueryable: widgetConfig?.isFieldQueryable,
              entityName: widgetConfig?.selectedSystemFieldBlock?.entityName,
              fieldCategory:
                widgetConfig?.selectedSystemFieldBlock?.fieldCategory
            })
          }
        }
      });
      // analytics flow
      const data = [];
      forEach(otherConfig, (list) => {
        if ([...multipleKeys, item?.blockId]?.includes(list?.blockId)) {
          data?.push({ key: list?.name, value: list?.label });
        }
      });
      sendAnalyticsData({
        [systemLabel]: data
      });
    } else {
      const newSelectedOptions = multipleKeys?.filter((selectedItem) => {
        return selectedItem !== item?.blockId;
      });
      setMultipleKeys([...newSelectedOptions]);
      setSavedData({
        systemFields: {
          ...savedData?.systemFields,
          [fieldId]: {
            type,
            value: [...newSelectedOptions],
            pageId,
            ...(widgetConfig?.selectedSystemFieldBlock?.entityName && {
              isFieldQueryable: widgetConfig?.isFieldQueryable,
              entityName: widgetConfig?.selectedSystemFieldBlock?.entityName,
              fieldCategory:
                widgetConfig?.selectedSystemFieldBlock?.fieldCategory
            })
          }
        }
      });
      // analytics flow
      const data = [];
      forEach(otherConfig, (list) => {
        if ([...newSelectedOptions]?.includes(list?.blockId)) {
          data?.push({ key: list?.name, value: list?.label });
        }
      });
      sendAnalyticsData({
        [systemLabel]: data
      });
    }
  };

  const handlePickListChange = (keys) => {
    if (keys?.length > 0) {
      if (!multiSelection) {
        setSelectedKey(keys);
        // analytics flow
        const option = find(otherConfig, (item) => keys === item?.blockId);
        sendAnalyticsData({
          [systemLabel]: { key: option?.name, value: option?.label }
        });
      } else {
        setMultipleKeys([...multipleKeys, keys]);
        // analytics flow
        const data = [];
        forEach(otherConfig, (list) => {
          if (keys?.includes(list?.blockId)) {
            data?.push({ key: list?.name, value: list?.label });
          }
        });
        sendAnalyticsData({
          [systemLabel]: data
        });
      }
    } else if (!multiSelection) {
      setSelectedKey(null);
      form?.setFieldsValue({
        systemFields: {
          [fieldId]: {
            [type]: {
              value: null
            }
          }
        }
      });
      // analytics flow
      sendAnalyticsData({
        [systemLabel]: null
      });
    } else {
      setMultipleKeys([]);
      form?.setFieldsValue({
        systemFields: {
          [fieldId]: {
            [type]: {
              value: []
            }
          }
        }
      });
      // analytics flow
      sendAnalyticsData({
        [systemLabel]: []
      });
    }
  };
  return (
    <div>
      {displayStyle === 'DROPDOWN' ? (
        <Form.Item
          name={['systemFields', fieldId, 'PICK_LIST', 'value']}
          rules={[
            { required, message: 'This field is required. Please fill it in.' }
          ]}
        >
          <SelectComponent
            className="fill-width pick-list-drop-down"
            filterOption={(input, option) =>
              option?.label?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
            }
            optionLabelProp="label"
            mode={multiSelection ? 'multiple' : ''}
            onChange={handlePickListChange}
          >
            {map(
              filter(otherConfig, (item) => item?.blockCheck),
              (item) => {
                return (
                  <Option
                    key={item?.blockId}
                    value={item?.blockId}
                    label={item?.label}
                  >
                    <div key={item?.blockId} className="dropdown-option">
                      <span className="option-title">{item?.label}</span>
                      {renderDescriptions && (
                        <span className="option-description">
                          {item?.blockDescription || item?.description}
                        </span>
                      )}
                    </div>
                  </Option>
                );
              }
            )}
          </SelectComponent>
        </Form.Item>
      ) : (
        <CommonPickListWidget
          displayStyle={displayStyle}
          renderImages={images}
          imagePreview={imagePreview}
          renderDescriptions={renderDescriptions}
          haveLabel
          renderItems={map(otherConfig, (item) => {
            return {
              key: item?.blockId,
              ...item
            };
          })?.filter((item) => item?.blockCheck)}
          selectedKey={selectedKey}
          multipleKeys={multipleKeys}
          form={form}
          handleRedirect={handleRedirect}
          isMultiple={multiSelection}
          keyName="blockId"
          primaryColor={primaryColor}
        />
      )}
      {description && <div className="mt-37">{descriptionText}</div>}
    </div>
  );
};

export default RenderSystemPickList;
